import React from "react";

import Image from "next/image";
import Link from "next/link";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { useRouter } from "next/router";

import styles from "./Sidebar.module.scss";

const contentVariant = {
  contentInitial: {
    x: "-100vw"
  },
  contentAnimate: {
    x: 0
  },
  contentExit: {
    x: "-100vw"
  }
};

const variant = {
  containerInitial: {
    opacity: 0
  },
  containerAnimate: {
    opacity: 1
  },
  containerExit: {
    opacity: 0
  }
};

const menu = [
  {
    icon: "/images/icons/photobooth.svg",
    title: "Hyperbooth",
    url: "/styles"
  },
  {
    icon: "/images/icons/editor.svg",
    title: "Photo Results",
    url: "/results"
  }
];

interface SideBarProps {
  setShowMenu: (val: boolean) => void;
  show: boolean;
}

const Sidebar = ({ setShowMenu, show = false }: SideBarProps) => {
  const { asPath, push, basePath } = useRouter();
  return (
    <AnimatePresence>
      {show && (
        <div
          className={styles.sidebarContainer}
          onClick={() => setShowMenu(!show)}
        >
          <motion.div
            className={styles.sidebarContentContainer}
            variants={contentVariant}
            initial="contentInitial"
            animate="contentAnimate"
            exit="contentExit"
            transition={{ duration: 0.3, type: "tween" }}
          >
            {menu.map((item) => (
              <div
                key={item.title}
                className={classNames(styles.sidebarMenuItem, {
                  [styles.active]: asPath === item.url
                })}
                onClick={() => push(item.url)}
              >
                <Image
                  src={`${basePath}${item.icon}`}
                  width={35}
                  height={35}
                  alt={item.title}
                />
                <span>{item.title}</span>
              </div>
            ))}
            <div className={styles.termsOfServiceWrapper}>
              <Link href="/terms-of-service">Terms of Service</Link>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </div>
          </motion.div>
          <motion.div
            className={styles.shadowContainer}
            variants={variant}
            initial="containerInitial"
            animate="containerAnimate"
            exit="containerExit"
            transition={{ duration: 0.5, type: "tween" }}
          />
        </div>
      )}
    </AnimatePresence>
  );
};

export { Sidebar };
