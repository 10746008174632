interface ImageDetails {
  url: string;
  alt: string;
  aspectRatio: string;
}

export type ImageArray = ImageDetails[][];

export const PHOTO_GRID_JOB: ImageArray = [
  //col 1
  [
    {
      url: "/images/landingpage/imagegrid/job/1x1/1A.webp",
      alt: "ai superhero generator",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/job/1x1/1B.webp",
      alt: "ai cyberpunk art",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/3x4/2A.webp",
      alt: "ai professional headshot",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/job/3x4/2B.webp",
      alt: "ai graduation photo man",
      aspectRatio: "3 / 4"
    }
  ],
  //col 2
  [
    {
      url: "/images/landingpage/imagegrid/job/1x1/3A.webp",
      alt: "ai cosplay circus ",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/job/1x1/3B.webp",
      alt: "ai anime generator image",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/4x3/4A.webp",
      alt: "ai graduation photo woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/job/4x3/4B.webp",
      alt: "ai fantasy fairy",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/3x4/5A.webp",
      alt: "ai yearbook woman",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/job/3x4/5B.webp",
      alt: "ai anime samuri",
      aspectRatio: "3 / 4"
    }
  ],
  //col 3
  [
    {
      url: "/images/landingpage/imagegrid/job/9x16/6A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "9 / 16"
    },
    {
      url: "/images/landingpage/imagegrid/job/9x16/6B.webp",
      alt: "ai superhero woman",
      aspectRatio: "9 / 16"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/4x3/7A.webp",
      alt: "ai art splash art man",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/job/4x3/7B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/1x1/8A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/job/1x1/8B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 4
  [
    {
      url: "/images/landingpage/imagegrid/job/4x3/9A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/job/4x3/9B.webp",
      alt: "ai superhero woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/3x4/10A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/job/3x4/10B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/1x1/11A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/job/1x1/11B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 5
  [
    {
      url: "/images/landingpage/imagegrid/job/3x4/12A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/job/3x4/12B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/job/1x1/13A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/job/1x1/13B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ]
];

export const PHOTO_GRID_HOME: ImageArray = [
  //col 1
  [
    {
      url: "/images/landingpage/imagegrid/home/1x1/1A.webp",
      alt: "ai superhero generator",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/home/1x1/1B.webp",
      alt: "ai cyberpunk art",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/3x4/2A.webp",
      alt: "ai professional headshot",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/home/3x4/2B.webp",
      alt: "ai graduation photo man",
      aspectRatio: "3 / 4"
    }
  ],
  //col 2
  [
    {
      url: "/images/landingpage/imagegrid/home/1x1/3A.webp",
      alt: "ai cosplay circus ",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/home/1x1/3B.webp",
      alt: "ai anime generator image",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/4x3/4A.webp",
      alt: "ai graduation photo woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/home/4x3/4B.webp",
      alt: "ai fantasy fairy",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/3x4/5A.webp",
      alt: "ai yearbook woman",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/home/3x4/5B.webp",
      alt: "ai anime samuri",
      aspectRatio: "3 / 4"
    }
  ],
  //col 3
  [
    {
      url: "/images/landingpage/imagegrid/home/9x16/6A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "9 / 16"
    },
    {
      url: "/images/landingpage/imagegrid/home/9x16/6B.webp",
      alt: "ai superhero woman",
      aspectRatio: "9 / 16"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/4x3/7A.webp",
      alt: "ai art splash art man",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/home/4x3/7B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/1x1/8A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/home/1x1/8B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 4
  [
    {
      url: "/images/landingpage/imagegrid/home/4x3/9A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/home/4x3/9B.webp",
      alt: "ai superhero woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/3x4/10A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/home/3x4/10B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/1x1/11A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/home/1x1/11B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 5
  [
    {
      url: "/images/landingpage/imagegrid/home/3x4/12A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/home/3x4/12B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/home/1x1/13A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/home/1x1/13B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ]
];

export const PHOTO_GRID_SOCIAL: ImageArray = [
  //col 1
  [
    {
      url: "/images/landingpage/imagegrid/social/1x1/1A.webp",
      alt: "ai superhero generator",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/social/1x1/1B.webp",
      alt: "ai cyberpunk art",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/3x4/2A.webp",
      alt: "ai professional headshot",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/social/3x4/2B.webp",
      alt: "ai graduation photo man",
      aspectRatio: "3 / 4"
    }
  ],
  //col 2
  [
    {
      url: "/images/landingpage/imagegrid/social/1x1/3A.webp",
      alt: "ai cosplay circus ",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/social/1x1/3B.webp",
      alt: "ai anime generator image",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/4x3/4A.webp",
      alt: "ai graduation photo woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/social/4x3/4B.webp",
      alt: "ai fantasy fairy",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/3x4/5A.webp",
      alt: "ai yearbook woman",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/social/3x4/5B.webp",
      alt: "ai anime samuri",
      aspectRatio: "3 / 4"
    }
  ],
  //col 3
  [
    {
      url: "/images/landingpage/imagegrid/social/9x16/6A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "9 / 16"
    },
    {
      url: "/images/landingpage/imagegrid/social/9x16/6B.webp",
      alt: "ai superhero woman",
      aspectRatio: "9 / 16"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/4x3/7A.webp",
      alt: "ai art splash art man",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/social/4x3/7B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/1x1/8A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/social/1x1/8B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 4
  [
    {
      url: "/images/landingpage/imagegrid/social/4x3/9A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/social/4x3/9B.webp",
      alt: "ai superhero woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/3x4/10A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/social/3x4/10B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/1x1/11A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/social/1x1/11B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 5
  [
    {
      url: "/images/landingpage/imagegrid/social/3x4/12A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/social/3x4/12B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/social/1x1/13A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/social/1x1/13B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ]
];

export const PHOTO_GRID_DATING: ImageArray = [
  //col 1
  [
    {
      url: "/images/landingpage/imagegrid/dating/1x1/1A.webp",
      alt: "ai superhero generator",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/dating/1x1/1B.webp",
      alt: "ai cyberpunk art",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/3x4/2A.webp",
      alt: "ai professional headshot",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/dating/3x4/2B.webp",
      alt: "ai graduation photo man",
      aspectRatio: "3 / 4"
    }
  ],
  //col 2
  [
    {
      url: "/images/landingpage/imagegrid/dating/1x1/3A.webp",
      alt: "ai cosplay circus ",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/dating/1x1/3B.webp",
      alt: "ai anime generator image",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/4x3/4A.webp",
      alt: "ai graduation photo woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/dating/4x3/4B.webp",
      alt: "ai fantasy fairy",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/3x4/5A.webp",
      alt: "ai yearbook woman",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/dating/3x4/5B.webp",
      alt: "ai anime samuri",
      aspectRatio: "3 / 4"
    }
  ],
  //col 3
  [
    {
      url: "/images/landingpage/imagegrid/dating/9x16/6A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "9 / 16"
    },
    {
      url: "/images/landingpage/imagegrid/dating/9x16/6B.webp",
      alt: "ai superhero woman",
      aspectRatio: "9 / 16"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/4x3/7A.webp",
      alt: "ai art splash art man",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/dating/4x3/7B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/1x1/8A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/dating/1x1/8B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 4
  [
    {
      url: "/images/landingpage/imagegrid/dating/4x3/9A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/dating/4x3/9B.webp",
      alt: "ai superhero woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/3x4/10A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/dating/3x4/10B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/1x1/11A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/dating/1x1/11B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 5
  [
    {
      url: "/images/landingpage/imagegrid/dating/3x4/12A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/dating/3x4/12B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/dating/1x1/13A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/dating/1x1/13B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ]
];

export const PHOTO_GRID: ImageArray = [
  //col 1
  [
    {
      url: "/images/landingpage/imagegrid/professional/1x1/1A.webp",
      alt: "ai superhero generator",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/professional/1x1/1B.webp",
      alt: "ai cyberpunk art",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/3x4/2A.webp",
      alt: "ai professional headshot",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/professional/3x4/2B.webp",
      alt: "ai graduation photo man",
      aspectRatio: "3 / 4"
    }
  ],
  //col 2
  [
    {
      url: "/images/landingpage/imagegrid/professional/1x1/3A.webp",
      alt: "ai cosplay circus ",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/professional/1x1/3B.webp",
      alt: "ai anime generator image",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/4x3/4A.webp",
      alt: "ai graduation photo woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/professional/4x3/4B.webp",
      alt: "ai fantasy fairy",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/3x4/5A.webp",
      alt: "ai yearbook woman",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/professional/3x4/5B.webp",
      alt: "ai anime samuri",
      aspectRatio: "3 / 4"
    }
  ],
  //col 3
  [
    {
      url: "/images/landingpage/imagegrid/professional/9x16/6A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "9 / 16"
    },
    {
      url: "/images/landingpage/imagegrid/professional/9x16/6B.webp",
      alt: "ai superhero woman",
      aspectRatio: "9 / 16"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/4x3/7A.webp",
      alt: "ai art splash art man",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/professional/4x3/7B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/1x1/8A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/professional/1x1/8B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 4
  [
    {
      url: "/images/landingpage/imagegrid/professional/4x3/9A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/professional/4x3/9B.webp",
      alt: "ai superhero woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/3x4/10A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/professional/3x4/10B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/1x1/11A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/professional/1x1/11B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 5
  [
    {
      url: "/images/landingpage/imagegrid/professional/3x4/12A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/professional/3x4/12B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/professional/1x1/13A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/professional/1x1/13B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ]
];

export const PHOTO_GRID_FITNESS: ImageArray = [
  //col 1
  [
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/1A.webp",
      alt: "ai superhero generator",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/1B.webp",
      alt: "ai cyberpunk art",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/2A.webp",
      alt: "ai professional headshot",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/2B.webp",
      alt: "ai graduation photo man",
      aspectRatio: "3 / 4"
    }
  ],
  //col 2
  [
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/3A.webp",
      alt: "ai cosplay circus ",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/3B.webp",
      alt: "ai anime generator image",
      aspectRatio: "1 / 1"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/4x3/4A.webp",
      alt: "ai graduation photo woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/4x3/4B.webp",
      alt: "ai fantasy fairy",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/5A.webp",
      alt: "ai yearbook woman",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/5B.webp",
      alt: "ai anime samuri",
      aspectRatio: "3 / 4"
    }
  ],
  //col 3
  [
    {
      url: "/images/landingpage/imagegrid/fitness/9x16/6A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "9 / 16"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/9x16/6B.webp",
      alt: "ai superhero woman",
      aspectRatio: "9 / 16"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/4x3/7A.webp",
      alt: "ai art splash art man",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/4x3/7B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/8A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/8B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 4
  [
    {
      url: "/images/landingpage/imagegrid/fitness/4x3/9A.webp",
      alt: "ai art futuristic woman",
      aspectRatio: "4 / 3"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/4x3/9B.webp",
      alt: "ai superhero woman",
      aspectRatio: "4 / 3"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/10A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/10B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/11A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/11B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ],
  //col 5
  [
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/12A.webp",
      alt: "ai art splash art man",
      aspectRatio: "3 / 4"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/3x4/12B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "3 / 4"
    }
  ],
  [
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/13A.webp",
      alt: "ai art splash art man",
      aspectRatio: "1 / 1"
    },
    {
      url: "/images/landingpage/imagegrid/fitness/1x1/13B.webp",
      alt: "ai headshot professional woman",
      aspectRatio: "1 / 1"
    }
  ]
];

export const PHOTO_WATERFALL = [
  // column 1
  [
    {
      tag: "Headshot",
      alt: "ai headshot professional man",
      url: "/images/landingpage/section5/118fbd70-fed8-4e89-8326-10110be1da8f.webp"
    },
    {
      tag: "Travel",
      alt: "ai photo generator travel image",
      url: "/images/landingpage/section5/4a7eabf7-7d60-4db1-ab20-6c13cbda107d.webp"
    },
    {
      tag: "Yearbook",
      alt: "ai yearbook 90s woman",
      url: "/images/landingpage/section5/5d629149-b8e8-4464-a165-5f05eb05773d.webp"
    },
    {
      tag: "Superhero",
      alt: "ai superhero man",
      url: "/images/landingpage/section5/05c3120b-9945-4e46-9598-a33a9701e8dd.webp"
    },
    {
      tag: "Beach Wedding",
      alt: "ai wedding photo bride",
      url: "/images/landingpage/section5/822c5b60-2da8-4ccb-aefe-46362a487368.webp"
    },
    {
      tag: "Old Money",
      alt: "ai image generator woman on horse",
      url: "/images/landingpage/section5/0dee284d-6e1a-4272-88a5-690d8c17e031.webp"
    }
  ],
  // column 2
  [
    {
      tag: "Cosplay",
      alt: "ai image generator soldier",
      url: "/images/landingpage/section5/a683d5c9-9950-43a9-9fa9-1f6ba1d3038b.webp"
    },
    {
      tag: "Villian",
      alt: "ai image generator villain woman",
      url: "/images/landingpage/section5/0cf8a373-2b5e-4e07-b1c8-25127dc3310d.webp"
    },
    {
      tag: "Old Money",
      alt: "ai headshot man",
      url: "/images/landingpage/section5/7d971ffb-caaa-44c3-be58-840b17386c6f.webp"
    },
    {
      tag: "Headshot",
      alt: "ai corporate headshot man",
      url: "/images/landingpage/section5/22b66c84-7a26-4708-ac22-736d88e3a867.webp"
    },
    {
      tag: "Adventure",
      alt: "ai image generator travel woman",
      url: "/images/landingpage/section5/1950ba0d-0e86-402b-bd2b-ca18eccc2ce9.webp"
    },
    {
      tag: "Artwork",
      alt: "ai steampunk image man",
      url: "/images/landingpage/section5/7a9226c6-fc71-4d47-ac72-bbfb626c4590.webp"
    }
  ],
  // column 3
  [
    {
      tag: "Cosplay",
      alt: "ai cosplay pirate woman",
      url: "/images/landingpage/section5/e4d38529-987b-48c2-a708-67121223d811.webp"
    },
    {
      tag: "Headshot",
      alt: "ai headhsot professional woman",
      url: "/images/landingpage/section5/05c71af8-5426-46bf-ad30-36f29dc09ecc.webp"
    },
    {
      tag: "Cosplay",
      alt: "ai cosplay image man",
      url: "/images/landingpage/section5/fa251f14-bf3c-494c-b4fb-c114006f5875.webp"
    },
    {
      tag: "White Wedding",
      alt: "ai wedding photo man",
      url: "/images/landingpage/section5/83f74294-c695-4784-b476-35b0f37ea15f.webp"
    },
    {
      tag: "Glamour",
      alt: "ai glamour photo woman",
      url: "/images/landingpage/section5/1ffa27d1-52e4-4d1c-939c-758b2e669a66.webp"
    },
    {
      tag: "Cafe",
      alt: "ai image generator cafe woman",
      url: "/images/landingpage/section5/109ec36e-588c-443a-bc74-6dd3986e86fd.webp"
    }
  ],
  // column 4
  [
    {
      tag: "Yearbook",
      alt: "ai yearbook goth girl",
      url: "/images/landingpage/section5/21ae3396-a207-4f04-a66e-3dece032428e.webp"
    },
    {
      tag: "Vintage",
      alt: "ai image generator vintage woman",
      url: "/images/landingpage/section5/048abd9e-8943-47fb-a116-33c355ec81bf.webp"
    },
    {
      tag: "Christmas",
      alt: "ai image generator christmas image",
      url: "/images/landingpage/section5/12ea2092-bff9-49e9-9afd-66c8b0ae5b1f.webp"
    },
    {
      tag: "Cyberpunk",
      alt: "ai cyberpunk man",
      url: "/images/landingpage/section5/5818f884-9a03-4fa4-b15e-46ef9de99dc8.webp"
    },
    {
      tag: "Headshot",
      alt: "ai headshot corporate headshot man",
      url: "/images/landingpage/section5/33187274-e46d-4619-bddb-035e4095672e.webp"
    },
    {
      tag: "Artwork",
      alt: "ai anime samuri man",
      url: "/images/landingpage/section5/c64b302ddc2b4def80df2b148f4d3b7.webp"
    }
  ],
  // column 5
  [
    {
      tag: "Glamour",
      alt: "ai image generator ai glamour woman",
      url: "/images/landingpage/section5/5ac94b86-1184-472d-ab24-ddcbdccf29c4.webp"
    },
    {
      tag: "Yearbook",
      alt: "ai yearbook 90s man ",
      url: "/images/landingpage/section5/46a9a647-1ecb-4517-91a1-838a92bc9cdf.webp"
    },
    {
      tag: "Fantasy",
      alt: "ai fantasy image man",
      url: "/images/landingpage/section5/636aeabf-e569-4a35-b4e6-a5a1107ab07f.webp"
    },
    {
      tag: "Travel",
      alt: "ai image generator travel man",
      url: "/images/landingpage/section5/0afdb489-fb70-4b0f-b14e-799007c5d930.webp"
    },
    {
      tag: "Adventure",
      alt: "ai image generator skydive man",
      url: "/images/landingpage/section5/2b90334b-6ee7-45d4-9a5c-01f5e403967a.webp"
    }
  ]
];

//customer reviews
type review = {
  name: string;
  job: string;
  description: string;
  img: string;
};
const reviews: review[] = [
  {
    name: "John Wilson",
    img: "/images/landingpage/review/review1.webp",
    job: "Marketing Professional",
    description:
      "HyperBooth.Ai transformed my professional image! With its advanced AI technology, I now have headshots that rival those of a high-end studio. The process was so simple, and the results exceeded my expectations."
  },
  {
    name: "Abigail Hana",
    img: "/images/landingpage/review/review2.webp",
    job: "Equity Researcher",
    description:
      "HyperBooth has exceeded my expectations! The sleek design and intuitive interface made customizing professional photos a breeze. Its responsiveness across devices ensures a seamless user experience. Thank you for such a fantastic product!"
  },
  {
    name: "Edward Davis",
    img: "/images/landingpage/review/review3.webp",
    job: "Software Engineer",
    description:
      "I used HyperBooth to create professional images for my portfolio and couldn't be happier with the results. The process was smooth and user-friendly, and the image quality is outstanding—crisp, clear, and polished. The customization options perfectly matched my style."
  }
];

const reviewsJob: review[] = [
  {
    name: "John Wilson",
    img: "/images/landingpage/review/review1.webp",
    job: "Marketing Professional",
    description:
      "Thanks to HyperBooth.Ai, I landed my dream job! The professional-grade headshots it generated instantly caught the eye of recruiters, giving me a competitive edge in the job market. It's a game-changer for anyone serious about advancing their career."
  },
  {
    name: "Abigail Hana",
    img: "/images/landingpage/review/review2.webp",
    job: "Equity Researcher",
    description:
      "Using HyperBooth.Ai for my personal branding was fantastic. The crisp images reflect my professional persona perfectly and have attracted new opportunities. Highly recommend it for anyone serious about their personal brand!"
  },
  {
    name: "Edward Davis",
    img: "/images/landingpage/review/review3.webp",
    job: "Software Engineer",
    description:
      "I couldn't afford a professional photoshoot, but HyperBooth.Ai made it easy to create polished photos for my job applications. The templates helped me impress employers and boost my career. HyperBooth.Ai is a lifesaver!"
  }
];

const reviewsFitness: review[] = [
  {
    name: "John Wilson",
    img: "/images/landingpage/review/review1.webp",
    job: "Marketing Professional",
    description:
      "HyperBooth.Ai helped me showcase my fitness progress like never before. I'm able to display my hard work and dedication with confidence. It's the perfect tool for anyone looking to inspire others on their fitness journey."
  },
  {
    name: "Abigail Hana",
    img: "/images/landingpage/review/review2.webp",
    job: "Equity Researcher",
    description:
      "I used to struggle with motivation in my fitness journey, but HyperBooth.Ai's fitness templates changed that. Now, I have high-definition images that inspire me to keep pushing forward every day. "
  },
  {
    name: "Edward Davis",
    img: "/images/landingpage/review/review3.webp",
    job: "Software Engineer",
    description:
      "HyperBooth.Ai's fitness templates have transformed my workout routine. They provide visual motivation and help me track my progress effectively. Sharing these templates with friends has boosted my confidence and accountability."
  }
];

const reviewsDating: review[] = [
  {
    name: "John Wilson",
    img: "/images/landingpage/review/review1.webp",
    job: "Marketing Professional",
    description:
      "I never realized the power of a great profile picture until I tried HyperBooth.Ai. The AI-generated headshots boosted my matches. It's like having a personal wingman in the world of online dating!"
  },
  {
    name: "Abigail Hana",
    img: "/images/landingpage/review/review2.webp",
    job: "Equity Researcher",
    description:
      "HyperBooth.Ai made a huge difference in my online dating experience! The amazing photos really capture my personality, and I've seen a big increase in matches and messages. It truly helped me stood out from the rest."
  },
  {
    name: "Edward Davis",
    img: "/images/landingpage/review/review3.webp",
    job: "Software Engineer",
    description:
      "I used to struggle with finding nice photos for dating apps, but HyperBooth.Ai changed that. Now, my profile is filled with quality images that truly represent me, and I've noticed a big increase in matches!"
  }
];

const reviewsSocial: review[] = [
  {
    name: "John Wilson",
    img: "/images/landingpage/review/review1.webp",
    job: "Marketing Professional",
    description:
      "HyperBooth.Ai revolutionizes my social media! Personalized avatars and AI-generated profiles help me stand out and connect effortlessly, making it feel like I have my own creative team."
  },
  {
    name: "Abigail Hana",
    img: "/images/landingpage/review/review2.webp",
    job: "Equity Researcher",
    description:
      "As a social media influencer, I love HyperBooth.Ai's diverse image templates! They let me create unique, engaging content that excites my followers, keeping my style fresh and creative with various themes."
  },
  {
    name: "Edward Davis",
    img: "/images/landingpage/review/review3.webp",
    job: "Software Engineer",
    description:
      "I no longer spend hours brainstorming post styles—HyperBooth.Ai covers everything. My feed looks diverse and fun, and my content creation process is now streamlined. HyperBooth.Ai has truly transformed my workflow!"
  }
];

// landing page configs.
export const LANDING_PAGE_CONFIGS = {
  "/": {
    title: "Best AI Photo Generator",
    metaTitle:
      "HyperBooth - The Best Choice for AI Image Generator of Realistic Headshots",
    subTitle: "The Best Choice for AI Image Generator of Realistic Headshots",
    description:
      "Generate artistic AI images effortlessly with a single click, achieving results in just 30 seconds.",
    description2:
      "Experience the convenience of rapid image creation, saving you valuable time and effort.",
    description3:
      "Save money while obtaining high-quality AI-generated images with just one selfie.",
    metaDescription:
      "Generate artistic AI images effortlessly with a single click, achieving results in just 30 seconds.",
    photoGrid: PHOTO_GRID_HOME,
    reviews: reviews
  },
  "/professional-ai-headshots": {
    title: "AI Professional Profile Generator",
    metaTitle:
      "HyperBooth - Achieve the most realistic AI-generated headshots possible",
    subTitle: "Achieve the most realistic AI-generated headshots possible",
    description:
      "Step into the future of headshot photography with our AI Professional Profile Generator.",
    description2:
      "Enjoy studio-quality portraits that reflect your essence using just one selfie.",
    description3:
      "Say goodbye to expensive shoots and hello to stunning, affordable, and tailored headshots.",
    metaDescription:
      "Achieve the most realistic AI-generated headshots possible",
    photoGrid: PHOTO_GRID,
    reviews: reviews
  },
  "/ai-job-application-image": {
    title: "AI Job Application Photo Generator",
    metaTitle: "HyperBooth - AI Job Application Headshot Generator",
    subTitle:
      "Boost your career prospects with our professional AI-enhanced headshots",
    description:
      "Elevate your career with our AI Job Application Photo Generator using just one selfie.",
    description2:
      "Get professional-grade AI-enhanced headshots to stand out in the competitive job market.",
    description3:
      "Boost your confidence and prospects with captivating, charismatic AI headshots.",
    metaDescription:
      "Boost your career prospects with our professional AI-enhanced headshots",
    photoGrid: PHOTO_GRID_JOB,
    reviews: reviewsJob
  },
  "/ai-dating-profile-images": {
    title: "AI Dating Photo Generator",
    metaTitle: "HyperBooth - AI Dating Photo Generator",
    subTitle: "Double or triple your matches and likes in dating",
    description:
      "Revolutionize your online dating with our AI Dating Photo Generator using just one selfie.",
    description2:
      "Say goodbye to lackluster photos and hello to more matches and likes effortlessly.",
    description3:
      "Captivating, authentic photos showcase your best self and amplify your appeal in dating.",
    metaDescription:
      "Revolutionize your online dating with our AI Dating Photo Generator using just one selfie.",
    photoGrid: PHOTO_GRID_DATING,
    reviews: reviewsDating
  },
  "/ai-fitness-photos": {
    title: "AI Fitness Photo Generator",
    metaTitle: "HyperBooth - AI Fitness Photo Generator",
    subTitle: "Display your ideal physique confidently in every photo",
    description:
      "One selfie to unlock your fitness potential with our AI Fitness Photo Generator for amazing results.",
    description2:
      "Showcase your hard work with impeccably crafted photos that highlight your dedication.",
    description3:
      "Radiate confidence and inspire others with photos capturing your ideal physique perfectly.",
    metaDescription:
      "Radiate confidence and inspire others with photos capturing your ideal physique perfectly.",
    photoGrid: PHOTO_GRID_FITNESS,
    reviews: reviewsFitness
  },
  "/ai-social-media-images": {
    title: "AI Social Photo Generator",
    metaTitle: "HyperBooth - AI Social Photo Generator",
    subTitle:
      "Create your unique avatar and share your personality with your community",
    description:
      "Use one selfie to elevate your online presence with our AI Social Photo Generator for a standout look.",
    description2:
      "Effortlessly craft your perfect digital persona with AI avatars expressing your style.",
    description3:
      "Stand out with eye-catching social media avatars and personalized profile pictures.",
    metaDescription:
      "Stand out with eye-catching social media avatars and personalized profile pictures.",
    photoGrid: PHOTO_GRID_SOCIAL,
    reviews: reviewsSocial
  }
};
