import styled from "styled-components";
import { Tooltip } from "react-bootstrap";
export const StyledTooltip = styled(Tooltip)`
  font-size: ${({ theme }) => theme.size.regular.m};
  position: fixed;
  margin-bottom: 10px;

  &.tooltip > div.tooltip-inner {
    padding: 1rem;
  }

  &.arrow {
    color: red;
    background-color: red;
  }
`;
