import React, { useEffect } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";
import cn from "classnames";

import { WEB_LINKS } from "@constants/index";
import { Button } from "@ui/Button";
import { Modal } from "@ui/Modal";

import { CreditCapsule } from "./CreditCapsule";
import { ProfileMenu } from "./ProfileMenu";

import styles from "./Nav.module.scss";

const Nav = () => {
  const { basePath, push } = useRouter();

  useEffect(() => {
    document.querySelector("html")?.classList.add("show-promotion");

    if (
      window.localStorage.getItem("closure-announcement-dismissed") !== "yes"
    ) {
      onBannerClick();
    }
  }, []);

  const onBannerClick = () => {
    Modal.alert({
      title: "IMPORTANT: The Closure of HyperBooth.Ai",
      onOk: () => {
        window.localStorage.setItem("closure-announcement-dismissed", "yes");
      },
      description: (
        <div className={styles.closureAnnouncement}>
          <p>Dear Users,</p>
          <p>
            We regret to inform you that HyperBooth.Ai will be closing down. The
            closure will occur in two phases to ensure you have time to save
            your generated photos.
          </p>

          <p>
            <div>Phase 1: Discontinuation of photo generation</div>
            <ul>
              <li>
                Effective August 17, the photo generation function will be
                disabled. Users will no longer be able to create new AI photos.
              </li>
              <li>
                However, all previously generated photos will remain accessible.
                Please take this opportunity to save and download your personal
                creations. You can delete your account if you wish, all your
                personal information will be deleted subsequently.
              </li>
            </ul>
          </p>
          <p>
            <div>Phase 2: Full website shutdown</div>
            <ul>
              <li>
                On September 16, the entire website will be taken offline, and
                access will no longer be available.
              </li>
            </ul>
          </p>
          <p>
            For our active subscribed users, please rest assured that you will
            receive a refund for your unused credits.
          </p>
          <p>
            We appreciate your understanding and support. Thank you for being a
            part of HyperBooth.Ai.
          </p>
          <p>Sincerely,</p>
          <p>The HyperBooth.Ai Team</p>
        </div>
      )
    });
  };

  return (
    <>
      <nav className={styles.navWrapper}>
        <div className={styles.nav}>
          <section className={styles.leftSection}>
            <Link href={WEB_LINKS.STYLES} className={styles.logoContainer}>
              <Image
                src={`${basePath}/images/hyperbooth_full_logo_master_colour_white.png`}
                alt="HyperGAI Logo"
                fill
              />
            </Link>
          </section>
          <section className={styles.rightSection}>
            <Button
              className="sm-hide"
              mobileSize="small"
              type="filledGrey"
              onClick={() => push(WEB_LINKS.RESULTS)}
            >
              Photo History
            </Button>
            <Button
              mobileSize="small"
              type="outlinedGradient"
              onClick={() => push(WEB_LINKS.COMMUNITY)}
            >
              Community
            </Button>
            <CreditCapsule />
            <ProfileMenu />
          </section>
        </div>
      </nav>
      <div
        className={cn(styles.promotionBanner, styles.clickable)}
        onClick={onBannerClick}
      >
        IMPORTANT NOTICE: The Closure of HyperBooth.Ai - Please Read for Details
      </div>
    </>
  );
};

export { Nav };
