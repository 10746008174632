import { ImageArray } from "@constants/landing-page";
import styles from "./DiamondPictures.module.scss";

export default function DiamondPictures({
  images
}: {
  images: ImageArray | null;
}) {
  //const ref = useRef(null);
  const photoSlicer = (st: number, end: number) => {
    return images?.slice(st, end).map((x, i) => (
      <div key={i} className={styles.imageContainer}>
        <img
          alt={x?.[0]?.alt}
          src={x?.[0]?.url}
          style={{ aspectRatio: x?.[0]?.aspectRatio }}
        />
        <img
          alt={x?.[1]?.alt}
          src={x?.[1]?.url}
          style={{
            aspectRatio: x?.[1]?.aspectRatio,
            animationDelay: `${i}s`
          }}
        />
      </div>
    ));
  };
  return (
    <div className={styles.container}>
      <div className={`sm-hide ${styles.verticalCol}`}>{photoSlicer(0, 2)}</div>

      <div className={` ${styles.verticalCol}`}>{photoSlicer(2, 5)}</div>

      <div className={` ${styles.verticalCol}`}>{photoSlicer(5, 8)}</div>

      <div className={` ${styles.verticalCol}`}>{photoSlicer(8, 11)}</div>

      <div className={`sm-hide ${styles.verticalCol}`}>
        {photoSlicer(11, 13)}
      </div>
    </div>
  );
}
