import React from "react";
import Link from "next/link";
import Image from "next/image";
import cn from "classnames";

import { Youtube } from "@ui/icons/Youtube";
import { Instagram } from "@ui/icons/Instagram";
import { Tiktok } from "@ui/icons/Tiktok";
import { Button } from "@ui/Button";
import { WEB_LINKS } from "@constants/index";
import { logAnalytics } from "@hypergai-frontend-mvp/shared-lib";

import styles from "./Header.module.scss";
import { useRouter } from "next/router";

interface HeaderProps {
  toggleMenu: () => void;
}

const HeaderLoggedOut = ({ toggleMenu }: HeaderProps) => {
  const router = useRouter();
  return (
    <header className={styles.headerContainer}>
      <nav className={styles.headerMenuBar}>
        <Link href={WEB_LINKS.HOME} className={styles.logoWrapper}>
          <Image
            src="/images/hyperbooth_full_logo_master_colour_white.png"
            alt="HyperStudio Logo"
            fill
          />
        </Link>
        <Image
          className={cn("md-hide", styles.mobileHeader)}
          src="/images/landingpage/menu.svg"
          width={32}
          height={32}
          alt="Menu Icon"
          onClick={toggleMenu}
        />
        <div className={cn("sm-hide", styles.webHeader)}>
          <div className={styles.entryPoints}>
            <Link className={styles.communityLinks} href={WEB_LINKS.COMMUNITY}>
              Community
            </Link>
            <Link className={styles.link} href={WEB_LINKS.PRICING}>
              Pricing
            </Link>
            <Link className={styles.link} href={WEB_LINKS.BLOG}>
              Blog
            </Link>
            <Link
              className={cn("lg-show", styles.link)}
              href={WEB_LINKS.DOWNLOAD}
            >
              Download App
            </Link>
          </div>
          <div className={styles.vl} />
          <div className={styles.buttonTray}>
            <Link
              className={styles.socialMediaBtn}
              href={WEB_LINKS.INSTAGRAM}
              onClick={() => {
                logAnalytics({ eventName: "instagram_button_clicks" });
              }}
              target="_blank"
              aria-label="instagram"
            >
              <Instagram size={24} color="white" />
            </Link>
            <Link
              className={styles.socialMediaBtn}
              href={WEB_LINKS.TIKTOK}
              onClick={() => {
                logAnalytics({ eventName: "tiktok_button_clicks" });
              }}
              target="_blank"
              aria-label="tiktok"
            >
              <Tiktok size={24} color="white" />
            </Link>
            <Link
              className={styles.socialMediaBtn}
              onClick={() => {
                logAnalytics({ eventName: "youtube_button_clicks" });
              }}
              href={WEB_LINKS.YOUTUBE}
              target="_blank"
              aria-label="youtube"
            >
              <Youtube size={24} color="white" />
            </Link>
          </div>
          <div className={styles.vl}></div>
          <div className={styles.buttonTray}>
            <Button
              type="plain"
              onClick={() => {
                router.push(WEB_LINKS.LOGIN);
              }}
            >
              Log In
            </Button>
            <Button
              type="primary"
              onClick={() => {
                router.push(WEB_LINKS.SIGN_UP);
              }}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export { HeaderLoggedOut };
