import ReactSlider from "react-slider";
import styled from "styled-components";

export const SliderContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey1};
  border-radius: 8px;
  padding: ${({ theme }) => theme.size.padding1};
  display: flex;
  justify-content: space-between;
  flex-flow: row;
`;
export const SliderSmall = styled(ReactSlider)`
  // For Sliders
  margin-top: 0.3rem;
  &.horizontal-slider {
    width: 23rem;
  }
`;
