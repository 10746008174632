import styled from "styled-components";

interface IconButtonProps {
  disabled?: boolean;
  $active?: boolean;
  $btnLabel?: boolean;
}

// main container
/**
 * @todo need to refactor all these buttons, its a disaster now.
 */
export const IconButtonStyle = styled.button<IconButtonProps>`
  border: 0px;
  background-color: inherit;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.size.fontWeight.regular};
  color: white;
  &.smallGrey {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    border-radius: 4px;
    // padding: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.white20};
  }

  /*  &.no-padding {
    padding: 0rem;
    border: 0px;
  } */
  // if active.
  background-color: ${(props) =>
    props.$active
      ? ` 
    #e0225b;
    &:hover {
       background-color:#e0225b;
    }
    `
      : ""};

  // if disabled.
  background-color: ${(props) =>
    props.disabled
      ? ` 
    background-color: inherit;
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color:inherit;
    }
    `
      : ""};
`;
