import React from "react";

import styles from "./AppFooter.module.scss";
import Link from "next/link";
import { WEB_LINKS } from "@constants/index";

export const AppFooter: React.FC = () => {
  return (
    <footer className={styles.navContainer}>
      <div className={styles.nav}>
        <div className={styles.leftSection}>
          <Link target="_blank" href={WEB_LINKS.ABOUT_US}>
            About Us
          </Link>
          <span className={styles.divider}></span>
          <Link href={WEB_LINKS.TNC}>Terms of Service</Link>
          <span className={styles.divider}></span>
          <Link href={WEB_LINKS.PRIVACY}>Privacy Policy</Link>
        </div>
        <div className={styles.rightSection}>
          <span>HyperGAI 2024, All Rights Reserved</span>
        </div>
      </div>
    </footer>
  );
};
