import { ReactComponent as Icon } from "./svg/Close.svg";

type Props = {
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
};

export const Close: React.FC<Props> = ({ size, color, className, onClick }) => {
  return (
    <Icon
      className={className}
      style={{ fontSize: size && size + "px", color }}
      onClick={() => onClick?.()}
    />
  );
};
