import styled from "styled-components";

export const TextAreaGrey = styled.textarea`
  // For Sliders
  border: 1px solid ${({ theme }) => theme.colors.white20};
  color: white;
  min-height: 3rem;
  background-color: ${({ theme }) => theme.colors.midGrey};
  border-radius: 8px;
  padding: 1rem;
  font-size: ${({ theme }) => theme.size.regular.s};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.white20};
    outline: none;
  }
`;

export const TextGrey = styled.input`
  // For Sliders
  border: 1px solid ${({ theme }) => theme.colors.white20};
  color: white;
  min-height: 3rem;
  background-color: ${({ theme }) => theme.colors.midGrey};
  border-radius: 8px;
  padding: 1rem;
  font-size: ${({ theme }) => theme.size.regular.s};
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.white20};
    outline: none;
  }
`;
