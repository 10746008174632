import ReactDOM from "react-dom";
import cn from "classnames";
import { Roboto } from "next/font/google";

import { Button } from "../Button";
import { Modal } from "./Modal";
import { Close } from "../Icons/Close";

import styles from "./Modal.module.scss";

const inter = Roboto({
  weight: ["100", "500", "400"],
  subsets: ["latin"]
});

type Props = {
  onCancel?(): void;
  onConfirm?(): void;
  title: string;
  description: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
};

export function confirm({
  onCancel,
  onConfirm,
  title,
  description,
  cancelButtonLabel = "Cancel",
  confirmButtonLabel = "Confirm"
}: Props) {
  const elm = document.createElement("div");
  document.body.appendChild(elm);

  function destroy() {
    ReactDOM.unmountComponentAtNode(elm);
    if (elm.parentNode) {
      elm.parentNode.removeChild(elm);
    }
  }

  function onCancelClick() {
    onCancel?.();
    destroy();
  }
  function onConfirmClick() {
    onConfirm?.();
    destroy();
  }
  function onCloseClick() {
    destroy();
  }

  ReactDOM.render(
    <Modal isOpen={true}>
      <div className={cn(inter.className, styles.confirmWrapper)}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.buttons}>
          <Button onClick={onCancelClick}>{cancelButtonLabel}</Button>
          <Button onClick={onConfirmClick} type="primary">
            {confirmButtonLabel}
          </Button>
        </div>
        <div className={styles.closeBtn} onClick={onCloseClick}>
          <Close />
        </div>
      </div>
    </Modal>,
    elm
  );
}
