import React, { ImgHTMLAttributes, useEffect, useRef, useState } from "react";

import { motion, HTMLMotionProps } from "framer-motion";
import cn from "classnames";

import styles from "./Image.module.scss";

export enum IMAGE_SIZE {
  BIG = "720px",
  SMALL = "360px"
}

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;
type Viewport = Record<string, string>;

interface Props extends ImageProps {
  imgSize?: Record<keyof Viewport, IMAGE_SIZE>;
  rounded?: boolean;
  framer?: boolean;
  framerProps?: HTMLMotionProps<"img">;
  loadBlurImage?: boolean;
}

// Default image when actual image is loading
const BLUR_DATA_URL: string =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8bwwAAjcBNBRvLA0AAAAASUVORK5CYII=";

const VIEWPORT_SIZE: Viewport = {
  sm: "(max-width: 767px)",
  md: "(min-width: 768px) and (max-width: 1023px)",
  lg: "(min-width: 1024px) and (max-width: 1279px)",
  xl: "(min-width: 1280px) and (max-width: 1919px)",
  xxl: "(min-width: 1920px)"
};

export const Image: React.FC<Props> = ({
  src,
  imgSize,
  rounded,
  framer,
  framerProps,
  className,
  alt,
  onLoad,
  loadBlurImage = true,
  ...rest
}) => {
  const [useFallback, setUseFallback] = useState<boolean>(false);
  const [imgSource, setImgSource] = useState<string>("");
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (src) {
      setImgSource(src);
    }
  }, [src]);

  const generateSize = () => {
    let resultArr = ["100vw"];

    for (let key in imgSize) {
      if (imgSize.hasOwnProperty(key)) {
        resultArr.unshift(`${VIEWPORT_SIZE[key]} ${imgSize[key]}`);
      }
    }

    // Return the final array
    return resultArr.toString();
  };

  return framer ? (
    <motion.img
      ref={imgRef}
      src={
        typeof window !== undefined
          ? src
          : imgSource || (loadBlurImage ? BLUR_DATA_URL : "")
      }
      className={cn(
        {
          [styles.rounded]: rounded
        },
        className
      )}
      onLoad={onLoad}
      alt={alt}
      {...framerProps}
    />
  ) : (
    <img
      alt={alt}
      ref={imgRef}
      src={
        typeof window !== undefined
          ? src
          : imgSource || (loadBlurImage ? BLUR_DATA_URL : "")
      }
      className={cn(
        {
          [styles.rounded]: rounded
        },
        className
      )}
      onLoad={onLoad}
      onError={() => {
        setUseFallback(true);
      }}
      {...(imgSource &&
        !useFallback &&
        imgSource.startsWith("https://") && {
          srcSet: `
          ${imgSource
            .replace(".jpg", "@l_360.webp")
            .replace("source", "thumbnail")} 360w,
          ${imgSource
            .replace(".jpg", "@l_720.webp")
            .replace("source", "thumbnail")} 720w
        `,
          sizes: generateSize()
        })}
      {...rest}
    />
  );
};
