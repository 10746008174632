import React, { useCallback, useState } from "react";
import cn from "classnames";
import { ArrowDown } from "../Icons/ArrowDown";

import styles from "./Accordion.module.scss";

interface Props {
  className?: string;
  list: Array<{ head: React.ReactNode; body: React.ReactNode }>;
}

export const Accordion: React.FC<Props> = ({ className, list }) => {
  const [current, setCurrent] = useState<{ [index: number]: boolean }>({});

  const onClick = useCallback(
    (i: number) => {
      setCurrent({ ...current, [i]: !current[i] });
    },
    [current]
  );

  return (
    <div className={cn(styles.accordionWrapper, className)}>
      {list.map((l, i) => (
        <div key={i} className={styles.accordionItem}>
          <div className={styles.head} onClick={() => onClick(i)}>
            <div className={styles.title}>{l.head}</div>
            <div className={cn(styles.arrow, current[i] && styles.rotate180)}>
              <ArrowDown color="white" size={18} />
            </div>
          </div>
          <div
            className={cn(
              styles.bodyWrapper,
              current[i] ? styles.expand : styles.collapse
            )}
          >
            <div className={styles.body}>
              <div className={styles.bodyInner}>{l.body}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
