import styled from "styled-components";
interface DecorativeButtonProp {
  $loading?: boolean;
  children: React.ReactNode;
}
export const DecorativeBtn = styled.button<DecorativeButtonProp>`
  background-color: ${({ theme }) => theme.colors.orange};
  border: 0px;
  cursor: pointer;
  color: white;
  //font-family: poppins;
  padding: ${({ theme }) => theme.size.padding1};
  border-radius: 1.2rem;
  font-size: ${({ theme }) => theme.size.regular.l};
  min-width: 10rem;
  // margin-left: 2rem;
  font-weight: ${({ theme }) => theme.size.fontWeight.semiBold};
  &.long {
    width: 20rem;
  }
  &.blue {
    background-color: ${({ theme }) => theme.colors.blue};
  }
  &.full {
    width: 100%;
    margin-left: 0rem;
  }
  &.generate {
    display: flex;
    align-items: center;
    width: 15rem;
    justify-content: center;
    height: 3.5;
    // margin-right: 0.4rem;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    &.grey {
      background-color: ${({ theme }) => theme.colors.midGrey};
    }
  }
  &.grey {
    background-color: inherit;
    border: solid 1px ${({ theme }) => theme.colors.white20};
  }
  &.outline {
    background-color: inherit;
    border: solid 1px ${({ theme }) => theme.colors.orange};
  }
  &.disabled {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    color: ${({ theme }) => theme.colors.white50};
    cursor: default;
  }
  &.small {
    font-size: ${({ theme }) => theme.size.regular.s};
    font-weight: ${({ theme }) => theme.size.fontWeight.regular};
    height: 3rem;
    padding: 0.2rem;
    margin-left: 1rem;
  }

  &.hyperbooth {
    border-radius: 8px;
    padding: 0.9rem;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-top: 0rem;
  }
`;
