import styled from "styled-components";

interface ContextMenuProps {
  $top: number;
  $left: number;
}
export const ContextMenuStyled = styled.ul<ContextMenuProps>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.midGrey};
  border-radius: 12px;
  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  z-index: 100000;

  hr {
    border: ${({ theme }) => theme.colors.white10} solid 1px;
  }

  li:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }

  li:hover:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  li:hover:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

export const ContextMenuItem = styled.li`
  padding: 1.5rem;
  cursor: pointer;
`;
