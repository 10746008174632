import React, { ReactNode, useState } from "react";
import { useMediaQuery } from "@hypergai/hgg";

import { useAuth } from "@shared-lib/auth";
import MobileMenu from "@components/mobile-menu/mobileMenu.component";
import { HeaderLoggedOut } from "@components/header-loggedout/HeaderLoggedOut";
import { Nav } from "@components/Nav";

import { HeadMetaTags } from "./HeadMetaTags";
import { WwwFooter } from "./WwwFooter";

import styles from "./WwwLayout.module.scss";
import { AnnouncementPopup } from "@components/AnnouncementPopup/AnnouncementPopup";

interface LayoutProps {
  title?: string;
  children: ReactNode;
}

// For https://www.hyperbooth.ai pages
export const WwwLayout = ({ children, title }: LayoutProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { isSm } = useMediaQuery();
  const { isLogin, user } = useAuth();

  const toggleMenu = () => {
    setShowMenu((value) => !value);
  };

  return (
    <>
      <HeadMetaTags title={title} />
      {isLogin ? (
        <>
          <Nav />
          <AnnouncementPopup announcement={user?.announcements || []} />
        </>
      ) : (
        <>
          <HeaderLoggedOut toggleMenu={toggleMenu} />
          <MobileMenu show={showMenu && isSm} />
        </>
      )}
      <div className={styles.contentWrapper}>
        <div className={styles.pinkBlur} />
        <div className={styles.contentContainer}>{children}</div>
      </div>
      <WwwFooter />
    </>
  );
};
