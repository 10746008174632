import { WEB_LINKS } from "@constants/index";

export function gotoLoginPage(redirectUrl?: true | string) {
  const appDomain = window.location.origin;
  const url = new URL(WEB_LINKS.LOGIN, appDomain);
  if (typeof redirectUrl === "string") {
    url.searchParams.set("redirect_url", redirectUrl);
  } else if (typeof redirectUrl === "boolean") {
    if (window.location.pathname === "/") {
      // don't redirect to landing page
      url.searchParams.set("redirect_url", "/styles");
    } else {
      url.searchParams.set("redirect_url", window.location.href);
    }
  }
  window.location.href = url.toString();
}

export function gotoSignUp(redirectUrl?: true | string) {
  const appDomain = window.location.origin;
  const url = new URL(WEB_LINKS.SIGN_UP, appDomain);
  if (typeof redirectUrl === "string") {
    url.searchParams.set("redirect_url", redirectUrl);
  } else if (typeof redirectUrl === "boolean") {
    if (window.location.pathname === "/") {
      // don't redirect to landing page
      url.searchParams.set("redirect_url", "/styles");
    } else {
      url.searchParams.set("redirect_url", window.location.href);
    }
  }
  window.location.href = url.toString();
}

// input: "https://s3.hyperbooth.ai/source/a60c04cac7e14c77a8c088d184729e26.jpg"
// get: "a60c04cac7e14c77a8c088d184729e26"
export function getImageIdFromUrl(imageUrl: string) {
  if (imageUrl.includes("s3.hyperbooth.ai/source/")) {
    return imageUrl
      .split("/source/")[1]
      .replace(".jpg", "")
      .replace("@preview", "");
  } else {
    return "";
  }
}
