import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { confirm } from "./confirm";
import { alert } from "./alert";

import styles from "./Modal.module.scss";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  useEffect(() => {
    // disable page scrolling when modal open
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={styles.modalWrapper}
          transition={{ duration: 0.2 }}
        >
          <div
            className={styles.shadow}
            onClick={() => {
              onClose?.();
            }}
          />
          <div className={styles.content}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Modal.confirm = confirm;
Modal.alert = alert;
