import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

// main container
export const DropdownItem = styled(Dropdown.Item)`
  &.dropdown-item {
    font-size: ${({ theme }) => theme.size.regular.s};
    // background-color: ${({ theme }) => theme.colors.lightGrey};
    color: white;
    padding: ${({ theme }) => theme.size.padding1};
    width: 100%;
    &:hover {
      background-color: ${({ theme }) => theme.colors.white20};
    }
  }
`;

export const DropdownHyper = styled(DropdownButton)`
  #dropdown-basic-button {
    font-size: ${({ theme }) => theme.size.regular.s};
    //z-index: 1000;
    width: 100%;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.grey1};
    border: 0px;
    padding: 1rem;
  }
  .dropdown-menu {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    width: 100%;
  }
`;
