import { useState, useEffect } from "react";

export const useMediaQuery = () => {
  const [matches, setMatches] = useState({
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isXxl: false,
    isBiggerThanSm: false,
    isBiggerThanMd: false,
    isBiggerThanLg: false,
    isBiggerThanXl: false
  });

  const checkSize = () => {
    const innerWidth = window.innerWidth;
    setMatches({
      isSm: innerWidth < 768,
      isMd: innerWidth >= 768 && innerWidth < 1024,
      isLg: innerWidth >= 1024 && innerWidth < 1280,
      isXl: innerWidth >= 1280 && innerWidth < 1920,
      isXxl: innerWidth >= 1920,
      isBiggerThanSm: innerWidth >= 768,
      isBiggerThanMd: innerWidth >= 1024,
      isBiggerThanLg: innerWidth >= 1280,
      isBiggerThanXl: innerWidth >= 1920
    });
  };

  useEffect(() => {
    checkSize();
    const listener = () => {
      checkSize();
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  return matches;
};
