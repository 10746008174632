import {
  gotoDiscordLogin,
  loginWithGoogle,
  loginWithFacebook,
  loginWithApple
} from "@shared-lib/auth";
import { SocialMediaList } from "../types/LoginTypes";
import URLS from "./urls.json";

export const STEP_LIST = ["Choose style", "Personalize", "Generate"];
export const AUTH_COOKIES_KEY = "AUTH_STATE";
export const UPLOAD_GUIDE = [
  {
    imageUrl: "/images/upload-guide/front.jpg",
    goodExample: true,
    title: "Frontal portrait"
  },
  {
    imageUrl: "/images/upload-guide/no-side-view.jpg",
    goodExample: false,
    title: "Side view"
  },
  {
    imageUrl: "/images/upload-guide/multiple-people.jpg",
    goodExample: false,
    title: "Multiple people"
  },
  {
    imageUrl: "/images/upload-guide/small-face.jpg",
    goodExample: false,
    title: "Small face"
  },
  {
    imageUrl: "/images/upload-guide/covered.jpg",
    goodExample: false,
    title: "Covered features"
  },
  {
    imageUrl: "/images/upload-guide/expressions.jpg",
    goodExample: false,
    title: "Unnatural expressions"
  },
  {
    imageUrl: "/images/upload-guide/nudity.jpg",
    goodExample: false,
    title: "Nudity"
  },
  {
    imageUrl: "/images/upload-guide/kid.jpg",
    goodExample: false,
    customStyle: true,
    title: "< 18 years old"
  }
];

export enum SIGN_UP_PAGE_TYPE {
  email,
  password,
  verify
}

export enum AUTH_TYPE {
  GOOGLE = "Google",
  FACEBOOK = "Facebook",
  DISCORD = "Discord",
  APPLE = "Apple"
}

const EXTERNAL_LINKS = {
  ABOUT_US:
    "https://blog.hyperbooth.ai/product/introducing-hyperbooth-ai-our-personalized-art-and-ai-photo-generator",
  BLOG: "https://blog.hyperbooth.ai/",
  INSTAGRAM: "https://www.instagram.com/hyperbooth_ai/",
  YOUTUBE: "https://www.youtube.com/channel/UCnZRR8XCm50FRhdrEmcSwfA",
  TIKTOK: "https://www.tiktok.com/@hyperbooth_ai?lang=en",
  GENERATION_TIPS:
    "https://blog.hyperbooth.ai/product/how-to-use-hyperbooth-ai",
  FEEDBACK_FORM:
    "https://qualtricsxmmbkllg237.qualtrics.com/jfe/form/SV_41TWwvbkmiTEaIC",
  APPSTORE: "https://apps.apple.com/us/app/hyperbooth-ai/id6477852891",
  PLAYSTORE:
    "https://play.google.com/store/apps/details?id=com.hypergai.hyperbooth",
  PROMPT_TIPS:
    "https://blog.hyperbooth.ai/product/how-to-write-a-good-text-prompt-on-hyperbooth-ai/"
} as const;

// merge URLS and EXTERNAL_LINKS
export const WEB_LINKS = Object.entries(URLS).reduce(
  (acc: { [name: string]: string }, [name, { path, domain }]) => {
    acc[name] = path;
    return acc;
  },
  EXTERNAL_LINKS
) as Record<keyof typeof URLS | keyof typeof EXTERNAL_LINKS, string>;

export enum GENERATION_STATUS {
  FAILED = "failed",
  SUCCESS = "succeeded",
  RUNNING = "running",
  PENDING = "pending"
}

export enum UPSCALE_STATUS {
  UPSCALE = 1,
  NORMAL = 0
}

export const SOCIAL_MEDIA_LIST: SocialMediaList = [
  {
    iconUrl: "/images/form-google.png",
    platform: AUTH_TYPE.GOOGLE,
    analytics: "google",
    action: loginWithGoogle
  },
  {
    iconUrl: "/images/form-facebook.png",
    platform: AUTH_TYPE.FACEBOOK,
    analytics: "facebook",
    action: loginWithFacebook
  },
  {
    iconUrl: "/images/form-discord.png",
    platform: AUTH_TYPE.DISCORD,
    analytics: "discord",
    action: gotoDiscordLogin
  },
  {
    iconUrl: "/images/form-apple.png",
    platform: AUTH_TYPE.APPLE,
    analytics: "apple",
    action: loginWithApple
  }
];
export const BLUR_DATA_URL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8bwwAAjcBNBRvLA0AAAAASUVORK5CYII=";
