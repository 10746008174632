import React from "react";
import Link from "next/link";
import cn from "classnames";

import { WEB_LINKS } from "@constants/index";
import styles from "./WwwFooter.module.scss";
import { logAnalytics } from "@hypergai-frontend-mvp/shared-lib";
import { Instagram } from "@ui/icons/Instagram";
import { Tiktok } from "@ui/icons/Tiktok";
import { Youtube } from "@ui/icons/Youtube";
import { useAuth } from "@shared-lib/auth";
import { AppStore } from "@ui/icons/AppStore";
import { PlayStore } from "@ui/icons/PlayStore";
import { useMediaQuery } from "@shared-lib/hooks/useMediaQuery";

export const WwwFooter: React.FC = () => {
  const { isBiggerThanMd } = useMediaQuery();
  const { user } = useAuth();
  const pricingLink = user ? WEB_LINKS.SUBSCRIPTION : WEB_LINKS.PRICING;

  return (
    <footer>
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.topSection}>
            <div className={styles.topTitle}>
              The Best Choice for AI Image Generator of Realistic Headshots
            </div>
            <div className={styles.topContainer}>
              <div className={styles.linkWrapper}>
                <span className={cn(styles.title)}>Photo Styles</span>
                <div className={styles.linkList}>
                  <Link href={WEB_LINKS.LANDING_PRO}>
                    Professional Profiles
                  </Link>
                  <Link href={WEB_LINKS.LANDING_JOB}>Job Application</Link>
                  <Link href={WEB_LINKS.LANDING_DATING}>Dating</Link>
                  <Link href={WEB_LINKS.LANDING_FITNESS}>Fitness</Link>
                  <Link href={WEB_LINKS.LANDING_SOCIAL}>Social</Link>
                </div>
              </div>
              <div className={styles.linkWrapper}>
                <div className={cn(styles.title)}>HyperBooth.Ai</div>
                <div className={styles.linkList}>
                  <Link target="_blank" href={WEB_LINKS.ABOUT_US}>
                    About Us
                  </Link>
                  <Link target="_blank" href={WEB_LINKS.BLOG}>
                    Blog
                  </Link>
                  <Link href={pricingLink}>Pricing</Link>
                  <Link target="_blank" href={WEB_LINKS.TNC}>
                    Terms Of Service
                  </Link>
                  <Link target="_blank" href={WEB_LINKS.PRIVACY}>
                    Privacy Policy
                  </Link>
                </div>
                <span
                  className={cn(styles.title, styles.externalLinkTitle)}
                  style={{ marginTop: "60px" }}
                >
                  Find us on:
                </span>
                <div
                  className={cn(styles.linkList, styles.externalLinks)}
                  style={{ marginTop: "0px" }}
                >
                  <Link
                    target="_blank"
                    href="https://chatgpt123.com/?utm_source=hyperbooth.ai"
                  >
                    ChatGPT123
                  </Link>
                  <Link target="_blank" href="https://www.yjpoo.com/">
                    映技派
                  </Link>
                </div>
              </div>
              <div className={styles.downloadWrapper}>
                <span className={styles.downloadTitle}>Download App</span>
                <div className={styles.downloadContainer}>
                  <Link
                    href={WEB_LINKS.APPSTORE}
                    target="_blank"
                    className={styles.downloadLink}
                    onClick={() => {
                      logAnalytics({
                        eventName: "apps_store_clicks"
                      });
                    }}
                  >
                    <AppStore size={isBiggerThanMd ? 32 : 28} />
                    <div className={styles.downloadText}>
                      <span>Download On The</span>
                      <span className={styles.storeName}>App Store</span>
                    </div>
                  </Link>
                  <Link
                    href={WEB_LINKS.PLAYSTORE}
                    target="_blank"
                    className={styles.downloadLink}
                    onClick={() => {
                      logAnalytics({
                        eventName: "google_play_clicks"
                      });
                    }}
                  >
                    <PlayStore size={isBiggerThanMd ? 28 : 26} />
                    <div className={styles.downloadText}>
                      <span>Get It On</span>
                      <span className={styles.storeName}>Google Play</span>
                    </div>
                  </Link>
                </div>
                <span className={cn(styles.title, styles.otherSitesTitle)}>
                  We are featured on:
                </span>
                <div className={styles.featuredSitesContainer}>
                  <Link
                    href="https://www.producthunt.com/posts/hyperbooth-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-hyperbooth&#0045;ai"
                    target="_blank"
                    onClick={() =>
                      logAnalytics({ eventName: "product_hunt_click" })
                    }
                  >
                    <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=455831&theme=dark"
                      alt="Find us on Product Hunt"
                    />
                  </Link>
                  <Link
                    href="https://www.toolify.ai/tool/hyperbooth-ai/?ref=embed"
                    target="_blank"
                    onClick={() => {
                      logAnalytics({ eventName: "toolify_ai_click" });
                    }}
                  >
                    <img
                      src="https://cdn.toolify.ai/featured_dark.svg"
                      alt="Featured on toolify.ai"
                    />
                  </Link>
                  <Link
                    href="https://theresanaiforthat.com/ai/hyperbooth-ai/?ref=featured&v=1372245"
                    target="_blank"
                    onClick={() => {
                      logAnalytics({ eventName: "theresanaiforthat_click" });
                    }}
                  >
                    <img
                      src="https://media.theresanaiforthat.com/featured3.png"
                      alt="Featured on theresanaiforthat.com"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.bottomSection}>
            <div className={styles.socialMedia}>
              <Link
                className={styles.socialMediaBtn}
                href={WEB_LINKS.INSTAGRAM}
                onClick={() => {
                  logAnalytics({ eventName: "instagram_button_clicks" });
                }}
                target="_blank"
                aria-label="instagram"
              >
                <Instagram size={30} color="white" />
              </Link>
              <Link
                className={styles.socialMediaBtn}
                href={WEB_LINKS.TIKTOK}
                onClick={() => {
                  logAnalytics({ eventName: "tiktok_button_clicks" });
                }}
                target="_blank"
                aria-label="tiktok"
              >
                <Tiktok size={30} color="white" />
              </Link>
              <Link
                className={styles.socialMediaBtn}
                onClick={() => {
                  logAnalytics({ eventName: "youtube_button_clicks" });
                }}
                href={WEB_LINKS.YOUTUBE}
                target="_blank"
                aria-label="youtube"
              >
                <Youtube size={30} color="white" />
              </Link>
              <span>
                <a href="mailto:support@hyperbooth.ai">
                  <u>support@hyperbooth.ai</u>
                </a>
              </span>
            </div>
            <div className={styles.companyName}>
              HyperGAI © 2024. All rights reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
