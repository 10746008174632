import React, { ButtonHTMLAttributes } from "react";
import cn from "classnames";
import styles from "./Button.module.scss";
import { Spinner } from "../Spinner";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface Props extends Omit<ButtonProps, "style" | "type"> {
  htmlType?: ButtonProps["type"];
  type?:
    | "plain"
    | "outlined"
    | "primary"
    | "filledRed"
    | "filledGrey"
    | "filledGreyLight"
    | "filledWhite"
    | "filledGradient"
    | "outlinedGradient";
  mobileSize?: "small" | "medium" | "large";
  desktopSize?: "medium" | "large";
  isBlock?: boolean; // similar to "display:block", fit button width to its parent width
  isLoading?: boolean;
}

export const Button: React.FC<Props> = ({
  htmlType,
  type = "outlined",
  mobileSize = "medium",
  desktopSize = "medium",
  isBlock = false,
  isLoading = false,
  className,
  children,
  ...rest
}) => {
  return (
    <button
      className={cn(
        styles.button,
        {
          [styles.block]: isBlock,
          [styles.disabled]: rest.disabled,
          [styles.typePlain]: type === "plain",
          [styles.typeOutlined]: type === "outlined",
          [styles.typePrimary]: type === "primary",
          [styles.typeFilledRed]: type === "filledRed",
          [styles.typeFilledGrey]: type === "filledGrey",
          [styles.typeFilledGreyLight]: type === "filledGreyLight",
          [styles.typeFilledWhite]: type === "filledWhite",
          [styles.typeFilledGradient]: type === "filledGradient",
          [styles.typeOutlinedGradient]: type === "outlinedGradient",
          [styles.sizeMobileSmall]: mobileSize === "small",
          [styles.sizeMobileMedium]: mobileSize === "medium",
          [styles.sizeMobileLarge]: mobileSize === "large",
          [styles.sizeDesktopMedium]: desktopSize === "medium",
          [styles.sizeDesktopLarge]: desktopSize === "large"
        },
        className
      )}
      type={htmlType}
      {...rest}
      disabled={rest.disabled || isLoading}
    >
      {isLoading && (
        <div className={styles.spinnerWrapper}>
          <Spinner isLoading size={20} />
        </div>
      )}
      {children}
    </button>
  );
};
