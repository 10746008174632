import React, { FC, useEffect, useState } from "react";

import { Modal } from "@ui/Modal";
import { Image } from "@ui/Image";

import { Button } from "@ui/Button";
import { useRouter } from "next/router";
import { Close } from "@ui/icons/Close";
import { useMediaQuery } from "@shared-lib/hooks/useMediaQuery";
import { Announcement, useAuth } from "@shared-lib/auth";
import classNames from "classnames";
import { ArrowLeft } from "@ui/icons/ArrowLeft";
import { ArrowRight } from "@ui/icons/ArrowRight";
import styles from "./AnnouncementPopup.module.scss";
import { patch } from "@shared-lib/api";

interface AnnouncementPopupProps {
  announcement: Announcement[];
}

const AnnouncementPopup: FC<AnnouncementPopupProps> = ({ announcement }) => {
  const router = useRouter();
  const { refreshAnnouncement } = useAuth();

  const { isSm } = useMediaQuery();
  const [active, setActive] = useState<number>(0);
  const [activeAnnouncement, setActiveAnnouncement] = useState<
    Announcement | undefined
  >(announcement?.[0]);
  const isFirst = active === 0;
  const isLast = active === announcement.length - 1;

  useEffect(() => {
    if (announcement?.length > 0) {
      setActiveAnnouncement(announcement[active]);
    }
  }, [active, announcement]);

  const onLeftClick = () => {
    if (!isFirst) {
      setActive(active - 1);
      setActiveAnnouncement(announcement[active - 1]);
    }
  };

  const onRightClick = () => {
    if (!isLast) {
      setActive(active + 1);
      setActiveAnnouncement(announcement[active + 1]);
    }
  };

  const onCloseClick = async () => {
    await patch(
      `/hyper-booth/me/announcement/${announcement[active]?.announcement_id}`,
      {
        status: true
      }
    );
    await refreshAnnouncement();

    setActiveAnnouncement(undefined);
  };

  return (
    <Modal isOpen={!!activeAnnouncement}>
      {announcement.length > 1 && (
        <>
          <div
            onClick={onLeftClick}
            className={classNames(styles.arrowBtn, styles.arrowLeft)}
          >
            <ArrowLeft size={20} />
          </div>
          <div
            onClick={onRightClick}
            className={classNames(styles.arrowBtn, styles.arrowRight)}
          >
            <ArrowRight size={20} />
          </div>
        </>
      )}

      <div className={styles.announcementWrapper}>
        <Image
          src={`${
            isSm
              ? "/images/mobile-announcement.jpg"
              : "/images/desktop-announcement.jpg"
          }`}
          alt="community annoucement"
          className={styles.annoucementImg}
        />
        <div className={styles.announcementContainer}>
          <span
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: activeAnnouncement?.description || ""
            }}
          />

          <div className={styles.buttonWrapper}>
            <Button
              type="filledGradient"
              onClick={async () => {
                await onCloseClick();
                router.push(activeAnnouncement?.cta?.link || "");
              }}
              mobileSize="medium"
              desktopSize="large"
              isBlock
            >
              {activeAnnouncement?.cta?.label}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.closeButtonWrapper}>
        <Close size={20} onClick={onCloseClick} />
      </div>
    </Modal>
  );
};

export { AnnouncementPopup };
