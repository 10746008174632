import styled from "styled-components";

interface ToggleButtonProps {
  $active?: boolean;
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  padding-left: ${({ theme }) => theme.size.padding3};
  padding-right: ${({ theme }) => theme.size.padding3};

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: inherit;

  color: white;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.white20};

  ${(props) =>
    props.$active
      ? ` 
      background-image: url("/hyperbooth/images/icons/buttonCheck.png");
      border: 1px solid #E0225B;
        background-repeat: no-repeat;
        background-position: right top;
        border-top-right-radius: 9px;
        color: #e0225b;
    `
      : ""};
`;
