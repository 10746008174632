import { ReactComponent as Icon } from "./svg/ArrowRight.svg";

type Props = {
  size?: number;
  color?: string;
  className?: string;
};

export const ArrowRight: React.FC<Props> = ({ size, color, className }) => {
  return (
    <Icon
      className={className}
      style={{ fontSize: size && size + "px", color }}
    />
  );
};
