import styles from "./TextAnimation.module.scss";

const textArry = [
  "350k+ hyper-creative AI photos created in total",
  "7000+ hyper-quality AI photos created daily",
  "300+ hyper-diverse styles to make your 1st photo"
];

export default function TextAnimation() {
  return (
    <div className={styles.container}>
      {textArry.map((text) => (
        <p key={text}>{text}</p>
      ))}
    </div>
  );
}
