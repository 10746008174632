import React, { ForwardedRef } from "react";
import { ReactComponent as Icon } from "./svg/ArrowDown.svg";

type Props = {
  size?: number;
  color?: string;
  className?: string;
};

export const ArrowDown: React.FC<Props> = ({ size, color, className }) => {
  return (
    <Icon
      className={className}
      style={{ fontSize: size && size + "px", color }}
    />
  );
};
