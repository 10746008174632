import Link from "next/link";
import { Popover } from "@hypergai-frontend-mvp/ui-framework";
import { Instagram } from "@ui/icons/Instagram";
import { Logout } from "@ui/icons/Logout";
import { Feedback } from "@ui/icons/Feedback";
import { AboutUs } from "@ui/icons/AboutUs";
import { Tiktok } from "@ui/icons/Tiktok";
import { History } from "@ui/icons/History";
import { Settings } from "@ui/icons/Settings";
import { Youtube } from "@ui/icons/Youtube";
import { Avatar } from "@ui/icons/Avatar";
import { Button } from "@ui/Button";
import { logout, useAuth } from "@shared-lib/auth";
import { gotoLoginPage } from "@utils/url";
import { useMediaQuery } from "@shared-lib/hooks/useMediaQuery";
import { WEB_LINKS } from "@constants/index";
import classNames from "classnames";

import styles from "./ProfileMenu.module.scss";
import { Crown } from "@ui/icons/Crown";
import { Credit } from "@ui/icons/Credit";
import { useRouter } from "next/router";

const ProfileMenu = () => {
  const { push } = useRouter();
  const { isSm } = useMediaQuery();
  const { user } = useAuth();
  const iconSize = 24;

  const onLogoutClick = async () => {
    await logout();
    gotoLoginPage();
  };

  return (
    <Popover
      showArrow={true}
      arrowProps={{ color: "#292929" }}
      contentProps={{
        side: "bottom",
        align: "end",
        sideOffset: 12
      }}
      content={
        <div className={styles.popoverWrapper}>
          <div className={styles.profileGroup}>
            <span className={styles.initial}>
              <Avatar size={48} color="white" />
            </span>
            <span className={styles.email}>{user?.email}</span>
            {isSm && (
              <div className={styles.upgradeContainer}>
                <Button
                  type="primary"
                  onClick={() => {
                    push(WEB_LINKS.SUBSCRIPTION);
                  }}
                >
                  <Crown size={16} color="white" /> Upgrade
                  <span className={styles.verticalDivider}>|</span>
                  <Credit size={16} />
                  <span>{user?.credits || 0}</span>
                </Button>
              </div>
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.menuItemGroup}>
            {isSm && (
              <Link className={styles.menuItem} href={WEB_LINKS.RESULTS}>
                Photo History
                <History size={20} className={styles.menuIcon} />
              </Link>
            )}

            <Link className={styles.menuItem} href={WEB_LINKS.SETTINGS}>
              Settings
              <Settings size={20} className={styles.menuIcon} />
            </Link>
          </div>
          <div className={styles.divider} />
          <div className={styles.menuItemGroup}>
            <Link
              className={styles.menuItem}
              href={WEB_LINKS.FEEDBACK_FORM}
              target="_blank"
            >
              Feedback
              <Feedback size={20} className={styles.menuIcon} />
            </Link>
            <Link
              className={styles.menuItem}
              href={WEB_LINKS.ABOUT_US}
              target="_blank"
            >
              About Us
              <AboutUs size={20} className={styles.menuIcon} />
            </Link>
          </div>
          <div className={styles.divider} />
          <div className={styles.menuItemGroup}>
            <div className={styles.menuItem} onClick={onLogoutClick}>
              <span>Log out</span>
              <Logout size={20} className={styles.menuIcon} />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.menuItemGroup}>
            <div className={styles.icons}>
              <Link
                className={styles.iconWrapper}
                href={WEB_LINKS.INSTAGRAM}
                target="_blank"
              >
                <Instagram size={iconSize} color="white" />
              </Link>
              <Link
                className={styles.iconWrapper}
                href={WEB_LINKS.TIKTOK}
                target="_blank"
              >
                <Tiktok size={iconSize} color="white" />
              </Link>

              <Link
                className={styles.iconWrapper}
                href={WEB_LINKS.YOUTUBE}
                target="_blank"
              >
                <Youtube size={iconSize} color="white" />
              </Link>
            </div>
          </div>
          <div
            className={classNames(styles.menuItemGroup, styles.supportWrapper)}
          >
            <div className={styles.supportItem}>
              <span>Questions? Contact:</span>
            </div>
            <Link
              href="mailto:support@hyperbooth.ai"
              className={styles.supportLink}
            >
              support@hyperbooth.ai
            </Link>
          </div>
        </div>
      }
    >
      <div className={styles.profileButtonWrapper}>
        <button className={styles.profileButton}>
          <Avatar size={24} color="white" />
        </button>
      </div>
    </Popover>
  );
};

export { ProfileMenu };
