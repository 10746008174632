type Props = {
  width?: string | number;
  height?: string | number;
  color?: string;
  className?: string;
};

export const ArrowSvg: React.FC<Props> = ({
  width = 20,
  height = 11,
  color,
  className
}) => {
  return (
    <div className={className} style={{ color }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.46356 0.843729L0 11L20 11L11.5364 0.843731C10.7369 -0.115777 9.26315 -0.115779 8.46356 0.843729Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};
