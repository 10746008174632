import styled from "styled-components";

export const FullWindowCentered = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.depth.mid};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  gap: 1rem;
  &.text-center {
    text-align: center;
  }

  &.darken {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.darkGrey {
    background-color: ${({ theme }) => theme.colors.darkGrey};
  }
`;
