import styled from "styled-components";

// main container
export const DecorativeBox = styled.div`
  //border: 1px ${({ theme }) => theme.colors.orange} solid;
  //padding: 1rem;
  border-radius: 9px;
  //margin: ${({ theme }) => theme.size.padding1};
  width: 100%;
`;

export const DecorativeInputBox = styled.input`
  background-color: inherit;
  border: 0px;
  padding: 1rem;
  color: white;
  font-size: 1.2rem;
  width: 100%;
  border: 1px ${({ theme }) => theme.colors.white20} solid;
  border-radius: 9px;
  &:focus {
    border: 1px ${({ theme }) => theme.colors.orange} solid;
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.secondaryGrey};
  }
`;

export const InputError = styled.span`
  color: ${({ theme }) => theme.colors.redError};
  font-size: 1.2rem;
  &:before {
    content: "";
    color: inherit;
    margin-right: 0.5rem;
    margin-bottom: -0.5rem;
    background-image: url("/hyperbooth/images/errorIcon.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
  }
`;
