import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import { HeadMetaTags } from "@components/Layout/HeadMetaTags";
import { useAuth } from "@shared-lib/auth";
import { getRedirectUrl } from "@shared-lib/utils/url";
import { WEB_LINKS } from "@constants/index";
import { Spinner } from "@ui/Spinner";

import styles from "./LoginLayout.module.scss";

interface LoginLayoutProps {
  title?: string;
  children: React.ReactNode;
}

export const LoginLayout = ({ children, title }: LoginLayoutProps) => {
  const { user } = useAuth();
  const searchParams = useSearchParams();
  const [message, setMessage] = useState<string>("");
  const { push } = useRouter();
  const mode = searchParams.get("mode");
  const signup = searchParams.get("signup");
  const isFetchingUser = user === undefined;

  useEffect(() => {
    if (signup === "reset") {
      setMessage("Your password has been reset! Try logging in.");
    }
  }, [signup, mode]);

  useEffect(() => {
    if (user && user.uid && user.emailVerified) {
      const redirectUrl =
        getRedirectUrl(window.location.search) || WEB_LINKS.STYLES;
      push(redirectUrl);
    }
  }, [user, push]);

  return (
    <>
      <HeadMetaTags title={title} />
      <div className={styles.promotionBanner}>
        <span>
          🔥🔥 Limited time offer - Start generating AI photos for FREE now!
        </span>
      </div>
      <div className={styles.LoginContainer}>
        <div className={styles.centerContainer}>
          <div className={styles.leftSide}>
            <div className={styles.logoWrapper}>
              <Image
                src="/images/hyperbooth_full_logo_master_colour_white.png"
                alt="HyperBooth Logo"
                fill
              />
            </div>
            <h1>
              Create free <br />
              AI Photos <br />
              <span className={styles.superText}>instantly</span>
              <br /> with just
              <br />
              <span className={styles.superText}>one selfie</span>
            </h1>
            <div className={styles.featureContainer}>
              <div className={styles.featureBox}>
                <h3>350k+</h3>
                <span>
                  hyper-creative photos <br />
                  created in total
                </span>
              </div>
              <div className={styles.featureBox}>
                <h3>7000+</h3>
                <span>
                  hyper-quality <br />
                  photos created daily
                </span>
              </div>
              <div className={styles.featureBox}>
                <h3>300+</h3>
                <span>
                  hyper-diverse styles <br />
                  to make your 1st photo
                </span>
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            {message !== "" && (
              <div className={styles.alertText}>{message}</div>
            )}
            <div className={styles.logoWrapper}>
              <Image
                src="/images/hyperbooth_full_logo_master_colour_white.png"
                alt="HyperBooth Logo"
                fill
              />
            </div>
            <div className={styles.loginBox}>
              {isFetchingUser ? (
                <div className={styles.spinnerWrapper}>
                  <Spinner isLoading />
                </div>
              ) : (
                <div
                  style={{ zIndex: 100, position: "relative", height: "100%" }}
                >
                  {children}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
