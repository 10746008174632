import styled from "styled-components";
import Form from "react-bootstrap/Form";

export const Checkbox = styled(Form.Check)`
  font-size: 1.2rem;
  .form-check-input[type="checkbox"] {
    background-color: inherit;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
  & .form-check-label {
    text-align: left;
    margin-left: 1rem;
    cursor: pointer;
  }

  &.mid {
    font-size: 1.4rem;
  }
`;
