import React, { useState } from "react";

import { useForm } from "react-hook-form";
import Image from "next/image";
import { Button } from "@hypergai-frontend-mvp/ui-framework";

import styles from "./access-code.module.scss";
import { post } from "@shared-lib/api";
import { logAnalytics } from "@hypergai-frontend-mvp/shared-lib";

type Form1 = {
  code: string;
};

type AccessResponse = {
  data: string;
  errorCode: string;
  errorMsg: string;
};

export function AccessCode({ completed }: { completed: () => void }) {
  const [errorMsg, setError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<Form1>();

  async function submitAccess(data: { code: string }) {
    setError("");
    console.log("call access code.", data);

    try {
      await post<AccessResponse>("/hyper-booth/activeInviteCode", {
        code: data.code
      });
      logAnalytics({
        eventName: "access_code_success"
      });
      completed && completed();
    } catch (error) {
      console.log("Error", error);
      setError("Invalid invite code");
    }
  }
  return (
    <div className={styles.centerBlackContainer}>
      <form
        className={styles.accessCodeContainer}
        onSubmit={handleSubmit(submitAccess)}
      >
        <Image
          src={`/images/hyperbooth_full_logo_master_colour_white.svg`}
          width={230}
          height={32}
          alt="HyperGAI Logo Small"
        />
        <h1>We have received your request for early access!</h1>
        You will receive an invite code in your registered email. Keep a
        lookout!
        <h2>Already have an invite code?</h2>
        <input
          className={styles.fancyInput}
          {...register("code", { required: "Access Code required" })}
          placeholder="Enter Code here"
        ></input>
        {errorMsg && <span className={styles.error}>{errorMsg}</span>}
        <div className={styles.buttonContainer}>
          <Button
            disabled={!isValid}
            type="primary"
            desktopSize="large"
            isBlock
          >
            Let&lsquo;s go!
          </Button>
        </div>
      </form>
    </div>
  );
}
