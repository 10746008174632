import ReactDOM from "react-dom";
import cn from "classnames";
import { Roboto } from "next/font/google";

import { Button } from "../Button";
import { Modal } from "./Modal";

import styles from "./Modal.module.scss";

const inter = Roboto({
  weight: ["100", "500", "400"],
  subsets: ["latin"]
});

type Props = {
  onOk?(): void;
  title: string;
  description: React.ReactNode;
};

export function alert({ onOk, title, description }: Props) {
  const elm = document.createElement("div");
  document.body.appendChild(elm);

  function destroy() {
    ReactDOM.unmountComponentAtNode(elm);
    if (elm.parentNode) {
      elm.parentNode.removeChild(elm);
    }
  }

  function onOkClick() {
    onOk?.();
    destroy();
  }

  ReactDOM.render(
    <Modal isOpen={true}>
      <div className={cn(inter.className, styles.alertWrapper)}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.buttons}>
          <Button onClick={onOkClick} type="primary">
            OK
          </Button>
        </div>
      </div>
    </Modal>,
    elm
  );
}
