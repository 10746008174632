import React, { ReactNode, useState } from "react";

import { Nav } from "@components/Nav";
import { Sidebar } from "@components/Sidebar";

import UserSurvey from "@components/user-survey/user-survey.component";
import { useAuth } from "@shared-lib/auth";
import { AccessCode } from "@components/access-code/acess-code.component";
import { WEB_LINKS } from "@constants/index";
import styles from "./AppLayout.module.scss";
import { AppFooter } from "./AppFooter";
import { HeadMetaTags } from "./HeadMetaTags";

interface LayoutProps {
  title?: string;
  showBlurGradientBg?: boolean;
  children: ReactNode;
  showFooter?: boolean;
}

// For https://app.hyperbooth.ai pages
export const AppLayout = ({
  title,
  showBlurGradientBg = false,
  children,
  showFooter = true
}: LayoutProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useAuth();

  return (
    <>
      <HeadMetaTags title={title} />
      <Nav />
      <Sidebar show={showMenu} setShowMenu={setShowMenu} />

      {!user?.hasAccessCode && (
        <AccessCode
          completed={() => {
            window.location.href = WEB_LINKS.STYLES;
          }}
        />
      )}
      {!user?.surveyDone && (
        <UserSurvey
          completed={() => {
            window.location.href = WEB_LINKS.STYLES;
          }}
        />
      )}

      {user?.surveyDone && user?.hasAccessCode && (
        <div className={styles.contentWrapper}>
          {showBlurGradientBg && <div className={styles.pinkBlur} />}
          {showBlurGradientBg && <div className={styles.purpleBlur} />}
          <div className={styles.contentContainer}>{children}</div>
          {showFooter && <AppFooter />}
        </div>
      )}
    </>
  );
};
