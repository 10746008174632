import styles from "@styles/hyperbooth.module.scss";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle
} from "react-compare-slider";
import { Image } from "@ui/Image";
import { useRouter } from "next/router";
import Script from "next/script";
import { Accordion } from "@ui/Accordion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion, useScroll, useTransform } from "framer-motion";
import dynamic from "next/dynamic";

import { useMediaQuery } from "@hypergai/hgg";
//import { logAnalytics } from "@hypergai-frontend-mvp/shared-lib";
import MobileMenu from "@components/mobile-menu/mobileMenu.component";
import { HeadMetaTags } from "@components/Layout/HeadMetaTags";
import { HeaderLoggedOut } from "@components/header-loggedout/HeaderLoggedOut";
import { WwwFooter } from "@components/Layout";
import { setCookie } from "cookies-next";
import { LogUTM, logAnalytics } from "@hypergai-frontend-mvp/shared-lib";
import useInViewport from "@hooks/useInViewport";
import classNames from "classnames";
import { WEB_LINKS } from "@constants/index";
import { ArrowRight } from "@ui/icons/ArrowRight";
import Link from "next/link";
import { LANDING_PAGE_CONFIGS, PHOTO_WATERFALL } from "@constants/landing-page";
import { duplicateArray } from "@shared-lib/utils/array";
import { getReactNodeText } from "@shared-lib/utils/index";
import TextPromptAnimation from "@components/landing-page/TextPromptAnimation";
import DiamondPictures from "@components/landing-page/DiamondPictures";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import TextAnimation from "@components/landing-page/TextAnimation";
import { Modal } from "@ui/Modal";

const ArrowAnimation = dynamic(
  () => import("../components/landing-page/ArrowAnimation.component"),
  { ssr: false }
);

const PicCollage = dynamic(
  () => import("../components/landing-page/PicCollage.componen"),
  { ssr: false }
);

const SlowTransition = {
  duration: 0.8,
  delay: 0.1,
  ease: [0, 0.71, 0.2, 1.01]
};

//WAter fall component
const Waterfall = styled.figure`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
  //margin-top: 20px;
  overflow-y: clip;
  height: 110rem;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    height: 85rem;
  }
  @media (max-width: 768px) {
    height: 62rem;
  }

  @keyframes scroll1 {
    to {
      // top: 0px;
    }
    from {
      top: -400px;
    }
  }

  $scrollTime: 20s;
  #grid1 {
    //grid-area: grid1;
    position: relative;
    top: 0px;
    animation: scroll1 $scrollTime linear infinite;
    //animation-delay: 1s;
  }
`;

export default function LandingPage({
  ssrData
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const router = useRouter();
  //const logoSize = 1;
  const { isMd, isSm, isBiggerThanMd } = useMediaQuery();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [arrowAnimationDisplay, setArrowAnimationDisplay] =
    useState<boolean>(false);

  const [compareSelect, setCompareSelect] = useState<boolean>(false);

  const scrollRef = useRef(null);

  const arrowAnimationRef = useRef<HTMLDivElement>(null);

  //set up diffrent landing pages landing pages:
  const landingPageConfig =
    LANDING_PAGE_CONFIGS?.[router.asPath as "/"] || LANDING_PAGE_CONFIGS["/"];

  useInViewport(arrowAnimationRef, undefined, () => {
    setArrowAnimationDisplay(true);
  });
  //const [waterfallImageHeight, setWaterfallImageHeight] = useState<number>(340);

  // for scrolling effect
  const { scrollYProgress } = useScroll({
    offset: ["start end", "end end"]
  });

  const scrollYProgressTransform = useTransform(
    scrollYProgress,
    // Map x from these values:
    [0, 1],
    // Into these values: -2000, 0
    [isSm ? -1000 : -2000, 0]
  );

  const scrollXProgressTransform = useTransform(
    scrollYProgress,
    // Map x from these values:
    [0, 1],
    // Into these values:
    [0, isSm ? -1000 : -2000]
  );

  useEffect(() => {
    if (router.isReady) {
      logAnalytics({
        eventName: "page_view"
      });
    }
  }, [router]);

  useEffect(() => {
    if (router.asPath.includes("@")) {
      // if KOL referal link is detected
      let referer = router.asPath.split("@")?.[1];
      referer = referer.split("?")?.[0];
      setCookie("KOL_LINK", referer);
      //Log a UTM link
      LogUTM(referer, "KOL");

      if (referer === "facebook" || referer === "tiktok") {
        setCookie("referral", referer, { domain: "hyperbooth.ai" });
      }

      //Log a GA event.
      logAnalytics({
        eventName: `KOL_affiliate`,
        tagname: referer
      });
      //log new V2  tag
      logAnalytics({
        eventName: `KOL_affiliate`,
        tagname_v2: referer
      });
    }

    if (
      window.localStorage.getItem("closure-announcement-dismissed") !== "yes"
    ) {
      onBannerClick();
    }
  }, [router.asPath]);

  const onBannerClick = () => {
    Modal.alert({
      title: "IMPORTANT: The Closure of HyperBooth.Ai",
      onOk: () => {
        window.localStorage.setItem("closure-announcement-dismissed", "yes");
      },
      description: (
        <div className={styles.closureAnnouncement}>
          <p>Dear Users,</p>
          <p>
            We regret to inform you that HyperBooth.Ai will be closing down. The
            closure will occur in two phases to ensure you have time to save
            your generated photos.
          </p>

          <p>
            <div>Phase 1: Discontinuation of photo generation</div>
            <ul>
              <li>
                Effective August 17, the photo generation function will be
                disabled. Users will no longer be able to create new AI photos.
              </li>
              <li>
                However, all previously generated photos will remain accessible.
                Please take this opportunity to save and download your personal
                creations. You can delete your account if you wish, all your
                personal information will be deleted subsequently.
              </li>
            </ul>
          </p>
          <p>
            <div>Phase 2: Full website shutdown</div>
            <ul>
              <li>
                On September 16, the entire website will be taken offline, and
                access will no longer be available.
              </li>
            </ul>
          </p>
          <p>
            For our active subscribed users, please rest assured that you will
            receive a refund for your unused credits.
          </p>
          <p>
            We appreciate your understanding and support. Thank you for being a
            part of HyperBooth.Ai.
          </p>
          <p>Sincerely,</p>
          <p>The HyperBooth.Ai Team</p>
        </div>
      )
    });
  };

  return (
    <>
      <HeadMetaTags
        title={landingPageConfig.metaTitle}
        description={landingPageConfig.metaDescription}
      >
        {landingPageConfig.photoGrid?.map((grid, index) => (
          <link key={index} rel="preload" href={grid[0].url} as="image" />
        ))}
      </HeadMetaTags>
      <>
        <MobileMenu show={showMenu && isSm} />
        <HeaderLoggedOut
          toggleMenu={() => {
            setShowMenu((x) => !x);
          }}
        ></HeaderLoggedOut>
        <div className={styles.promotionBanner} onClick={onBannerClick}>
          <span>
            IMPORTANT NOTICE: The Closure of HyperBooth.Ai - Please Read for
            Details
          </span>
        </div>
        <main className={styles.mainContentContainer}>
          <div className={styles.gradientCircle}></div>
          <div className={styles.mainSubContainer}>
            <section className={styles.mainHeader}>
              <motion.h1
                viewport={{ once: true }}
                initial={{ transform: "translateX(-30px)", opacity: 0 }}
                whileInView={{ transform: "translateX(0px)", opacity: 1 }}
                transition={{ ...SlowTransition, delay: 0 }}
              >
                <span className={styles.pinkHighlight}>
                  {landingPageConfig.title}
                </span>
              </motion.h1>
              <motion.h2
                viewport={{ once: true }}
                initial={{ transform: "translateX(-30px)", opacity: 0 }}
                whileInView={{ transform: "translateX(0px)", opacity: 1 }}
                transition={{ ...SlowTransition, delay: 0.1 }}
              >
                {landingPageConfig.subTitle}
              </motion.h2>

              <motion.section
                viewport={{ once: true }}
                className={styles.subtitle}
                initial={{ transform: "translateX(-30px)", opacity: 0 }}
                whileInView={{ transform: "translateX(0px)", opacity: 1 }}
                transition={{ ...SlowTransition, delay: 0.2 }}
              >
                <div className={styles.bulletPoints}>
                  <div>
                    <span>😎</span>
                    <span> {landingPageConfig.description}</span>
                  </div>
                  <div>
                    <span>🚀</span>
                    <span> {landingPageConfig.description2}</span>
                  </div>
                  <div>
                    <span>⭐</span>
                    <span> {landingPageConfig.description3}</span>
                  </div>
                </div>
              </motion.section>

              <Link className={styles.gradientButton} href={WEB_LINKS.STYLES}>
                Create AI Images for FREE
                <ArrowRight size={16} />
              </Link>
              <br />
              {!isBiggerThanMd && (
                <Link
                  className={` ${styles.gradientOutlineButton}`}
                  href={WEB_LINKS.DOWNLOAD}
                >
                  Download App
                  <ArrowRight size={16} />
                </Link>
              )}

              <TextAnimation />
            </section>

            <section className={styles.diamondImages}>
              <DiamondPictures images={landingPageConfig?.photoGrid} />
            </section>

            <section className={styles.darkBox}>
              <div className={styles.compareSection}>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ left: "-30px", opacity: 0 }}
                  whileInView={{ left: "0px", opacity: 1 }}
                  transition={{ ...SlowTransition, delay: 0.4 }}
                  className={styles.compareImageBox}
                >
                  <ReactCompareSlider
                    style={{ borderRadius: "10px", aspectRatio: "1" }}
                    handle={
                      <ReactCompareSliderHandle
                        linesStyle={{
                          height: "100%"
                        }}
                        buttonStyle={{ marginBottom: "-100px" }}
                      />
                    }
                    itemOne={
                      <div
                        className={styles.compareImage}
                        style={{
                          backgroundImage:
                            "url('/images/landingpage/before_image.webp')",
                          textAlign: "left"
                        }}
                      >
                        <label className={styles.before}>Before</label>
                      </div>
                    }
                    itemTwo={
                      <div
                        className={styles.compareImage}
                        style={{
                          backgroundImage:
                            "url('/images/landingpage/after_image.webp')",
                          textAlign: "right"
                        }}
                      >
                        <label>After</label>
                      </div>
                    }
                  />
                </motion.div>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ right: "-30px", opacity: 0 }}
                  whileInView={{ right: "0px", opacity: 1 }}
                  transition={{ ...SlowTransition, delay: 0.2 }}
                  className={styles.rightGridSection}
                >
                  <div className={styles.compareSectionText}>
                    <h2>
                      Use HyperBooth for your{" "}
                      <span className={styles.pinkHighlight}>Studio</span> and{" "}
                      <span className={styles.pinkHighlight}>
                        Artistic Photo
                      </span>{" "}
                      needs
                    </h2>
                    <div className={styles.hyperBoothLogo}>
                      350,000+ photos created
                    </div>
                    <p className={styles.smallText}>
                      Curate production-ready creative AI photos for your
                      professional or social media profiles, personal or work
                      projects, or simply for fun or lifestyle! Explore diverse
                      photo styles like anime, travel, fashion, graduation,
                      vintage, headshots, digital art, party and more!
                    </p>
                  </div>
                </motion.div>
              </div>
            </section>
            <section>
              <div className={styles.howToSection}>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ left: "-30px", opacity: 0 }}
                  whileInView={{ left: "0px", opacity: 1 }}
                  transition={SlowTransition}
                  className={styles.howtoSectionLeft}
                >
                  <h2>
                    Upload{" "}
                    <span className={styles.pinkHighlight}>one selfie </span>
                    to create photorealistic AI images
                  </h2>
                  <p className={styles.smallText}>
                    Repurpose a single selfie into multiple hyper-quality AI
                    photos in just one click. Simply choose your preferred photo
                    style, upload a selfie and watch the magic of artificial
                    intelligence unfold at hyper-speed!
                  </p>
                  <figure className={styles.inputImage}>
                    <span>Input Photo</span>
                    <Image
                      src="/images/landingpage/new_input_image.webp"
                      alt="ai selfie woman"
                      rounded
                    />
                  </figure>
                  <div className={styles.ArrowStroke}>
                    {arrowAnimationDisplay && <ArrowAnimation />}
                  </div>
                </motion.div>
                <div
                  className={styles.howtoSectionRight}
                  ref={arrowAnimationRef}
                >
                  <div className={styles.imageCollage}>
                    {arrowAnimationDisplay && <PicCollage />}
                  </div>
                </div>
              </div>
            </section>

            <section className={styles.textPromptDarkBox}>
              <h1>
                Design your own photo styles using
                <span className={styles.pinkHighlight}> text-to-image</span>
              </h1>
              <p>
                Let your creativity run wild! Create unique AI art by simply
                describing the photo style you want using a text prompt and
                watch AI transform the text to image. Upload a selfie to
                personalize the subject and make the AI images uniquely yours.
              </p>
              <TextPromptAnimation />
            </section>

            <section className={styles.pictureWaterFall}>
              <motion.h2
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ ...SlowTransition, delay: 0.2 }}
              >
                Choose from{" "}
                <span className={styles.pinkHighlight}>300+ photo styles</span>
              </motion.h2>
              <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ ...SlowTransition, delay: 0.2 }}
              >
                With our preset photo styles, you don{"'"}t need to do any of
                the hard work of writing prompts or setting parameters. Almost
                instantly, you could create the perfect AI photos for every
                occasion and project.
              </motion.p>
              <div className={styles.waterFallTopGradient}></div>
              <Waterfall ref={scrollRef}>
                <motion.aside
                  initial={{ transform: "translateY(-500px)" }}
                  whileInView={{ transform: "translateY(0px)" }}
                  transition={SlowTransition}
                  viewport={{ once: true }}
                  className={styles.grid1}
                  style={{ top: scrollXProgressTransform }}
                >
                  {duplicateArray(PHOTO_WATERFALL[0]).map(
                    ({ tag, alt, url }, i) => (
                      <motion.div key={i} className={styles.imgWrapper}>
                        <Image
                          alt={alt}
                          src={url}
                          className={styles.waterFallImg}
                        />
                        <label className={styles.waterFallCategory}>
                          {tag}
                        </label>
                      </motion.div>
                    )
                  )}
                </motion.aside>
                <motion.aside
                  initial={{ transform: "translateY(500px)" }}
                  whileInView={{ transform: "translateY(0px)" }}
                  transition={SlowTransition}
                  viewport={{ once: true }}
                  className={styles.grid2}
                  style={{ top: scrollYProgressTransform }}
                >
                  {duplicateArray(PHOTO_WATERFALL[1]).map(
                    ({ tag, alt, url }, i) => (
                      <motion.div key={i} className={styles.imgWrapper}>
                        <Image
                          alt={alt}
                          src={url}
                          className={styles.waterFallImg}
                        />
                        <label className={styles.waterFallCategory}>
                          {tag}
                        </label>
                      </motion.div>
                    )
                  )}
                </motion.aside>
                <motion.aside
                  initial={{ transform: "translateY(-500px)" }}
                  whileInView={{ transform: "translateY(0px)" }}
                  transition={SlowTransition}
                  viewport={{ once: true }}
                  className={styles.grid3}
                  style={{ top: scrollXProgressTransform }}
                >
                  {duplicateArray(PHOTO_WATERFALL[2]).map(
                    ({ tag, alt, url }, i) => (
                      <motion.div key={i} className={styles.imgWrapper}>
                        <Image
                          alt={alt}
                          src={url}
                          className={styles.waterFallImg}
                        />
                        <label className={styles.waterFallCategory}>
                          {tag}
                        </label>
                      </motion.div>
                    )
                  )}
                </motion.aside>
                {isMd || isSm || (
                  <>
                    <motion.aside
                      initial={{ transform: "translateY(500px)" }}
                      whileInView={{ transform: "translateY(0px)" }}
                      transition={SlowTransition}
                      viewport={{ once: true }}
                      className={styles.grid4}
                      style={{ top: scrollYProgressTransform }}
                    >
                      {duplicateArray(PHOTO_WATERFALL[3]).map(
                        ({ tag, alt, url }, i) => (
                          <motion.div key={i} className={styles.imgWrapper}>
                            <Image
                              alt={alt}
                              src={url}
                              className={styles.waterFallImg}
                            />
                            <label className={styles.waterFallCategory}>
                              {tag}
                            </label>
                          </motion.div>
                        )
                      )}
                    </motion.aside>
                    <motion.aside
                      initial={{ transform: "translateY(-500px)" }}
                      whileInView={{ transform: "translateY(0px)" }}
                      transition={SlowTransition}
                      viewport={{ once: true }}
                      className={styles.grid1}
                      style={{ top: scrollXProgressTransform }}
                    >
                      {duplicateArray(PHOTO_WATERFALL[4]).map(
                        ({ tag, alt, url }, i) => (
                          <motion.div key={i} className={styles.imgWrapper}>
                            <Image
                              alt={alt}
                              src={url}
                              className={styles.waterFallImg}
                            />
                            <label className={styles.waterFallCategory}>
                              {tag}
                            </label>
                          </motion.div>
                        )
                      )}
                    </motion.aside>
                  </>
                )}
              </Waterfall>
              <div className={styles.waterFallBtnGradient}></div>

              <div className={styles.buttonContainer}>
                <a className={styles.gradientButton} href={WEB_LINKS.SIGN_UP}>
                  View all styles
                  <ArrowRight size={16} />
                </a>
                <p> Create FREE AI photos now</p>
              </div>
            </section>

            <section className={styles.customerReviews}>
              <h1>
                Customer <span className={styles.pinkHighlight}>Reviews</span>
              </h1>
              <div className={styles.reviewContainer}>
                {landingPageConfig.reviews.map((x) => (
                  <div key={x.name} className={styles.review}>
                    <div className={styles.reviewHeader}>
                      <Image
                        alt={
                          "Happy customer : John Wilson, Marketing Professional"
                        }
                        src={x.img}
                        width={56}
                      />
                      <span>
                        {x.name}
                        <p>{x.job}</p>
                      </span>
                    </div>
                    <span className={styles.reviewText}>{x.description}</span>
                  </div>
                ))}
              </div>
            </section>

            <section className={styles.ProductCopareDarkBox}>
              <motion.header
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ ...SlowTransition, delay: 0.2 }}
              >
                <h2>Best Aragon, PFP AI photo alternative</h2>
                <p className={styles.smallText}>
                  Looking for the best AI image generator and thinking Aragon vs
                  PFP?
                  <br /> HyperBooth is the best AI image generator transforming
                  your selfies into realistic portraits and AI art with speed
                  and accurate results
                </p>
              </motion.header>
              <figure>
                <motion.div
                  initial={{ maxWidth: isSm ? "200px" : "750px", opacity: 0.2 }}
                  viewport={{ once: true }}
                  whileInView={{
                    maxWidth: isSm ? "500px" : "784px",
                    opacity: 1
                  }}
                  transition={{
                    duration: 0.3,
                    type: "tween",
                    ease: "easeInOut"
                  }}
                  className={styles.compareProductContainer}
                  onClick={() => {
                    setCompareSelect((x) => !x);
                  }}
                >
                  <div
                    className={classNames(styles.productCompareHero, {
                      [styles.bringBackAnimation]: !!compareSelect
                    })}
                  >
                    <div
                      className={classNames(
                        styles.productCompareItem,
                        styles.large
                      )}
                    >
                      <div
                        className={classNames(
                          styles.productImage,
                          styles.largeImage
                        )}
                        style={{
                          backgroundImage: `url("/images/landingpage/section6/HYPERBOOTH.webp")`
                        }}
                      ></div>
                      <aside>
                        <strong>HyperBooth</strong>
                        <ul className={styles.bulletPointsGood}>
                          <li>High photorealism</li>
                          <li>Creative photo style</li>
                          <li>Maintains ethnicity</li>
                          <li>Clear and sharp</li>
                        </ul>
                      </aside>
                    </div>
                  </div>
                  <div
                    className={classNames(styles.productCompareItems, {
                      [styles.bringForwardAnimation]: !!compareSelect
                    })}
                  >
                    <div className={styles.productCompareItem}>
                      <Image
                        alt="Generator A"
                        src="/images/landingpage/section6/GENERATOR_A.webp"
                      />
                      <aside>
                        <strong>PFP</strong>
                        <ul className={styles.bulletPoints}>
                          <li> Low photorealism</li>
                          <li>Average photo style</li>
                          <li className={styles.goodCheckmark}>
                            Maintains ethnicity
                          </li>
                          <li>Distorted</li>
                        </ul>
                      </aside>
                    </div>
                    <div className={styles.productCompareItem}>
                      <Image
                        alt="Generator B"
                        src="/images/landingpage/section6/GENERATOR_B.webp"
                      />
                      <aside>
                        <strong>Aragon</strong>
                        <ul className={styles.bulletPoints}>
                          <li className={styles.goodCheckmark}>
                            Mid photorealism
                          </li>
                          <li>Average Photo style</li>
                          <li>Changes ethnicity</li>
                          <li>Distorted</li>
                        </ul>
                      </aside>
                    </div>
                  </div>
                </motion.div>
              </figure>
            </section>

            <section className={styles.faq}>
              <h2>Frequently Asked Questions</h2>
              <Accordion list={FAQ_LIST} />
              {/* FAQ structured data. https://developers.google.com/search/docs/appearance/structured-data/faqpage */}
              <Script
                type="application/ld+json"
                strategy="beforeInteractive"
                dangerouslySetInnerHTML={{
                  __html: `{
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": ${JSON.stringify(
                      FAQ_LIST.map((faq) => ({
                        "@type": "Question",
                        name: faq.head,
                        acceptedAnswer: {
                          "@type": "Answer",
                          text: getReactNodeText(faq.body)
                        }
                      }))
                    )}
                  }`
                }}
              />
            </section>
          </div>
        </main>
        <WwwFooter />
      </>
    </>
  );
}

type PageProps = {
  path: string;
};
export const getServerSideProps = (async (ctx) => {
  return {
    props: {
      ssrData: {
        path: ctx.req.url || "/"
      }
    }
  };
}) satisfies GetServerSideProps<{ ssrData: PageProps }>;

const FAQ_LIST = [
  {
    head: "What is AI Image Generator for realistic headshots?",
    body: (
      <>
        <p>
          HyperBooth is a cutting-edge{" "}
          <Link
            href="https://blog.hyperbooth.ai/product/hyperbooth-ai-our-personalized-art-and-ai-photo-generator"
            target="_blank"
            className={styles.link}
          >
            AI headshot generator
          </Link>
          . It&lsquo;s so simple to use! You can get realistic AI headshot in
          just 3 steps:
        </p>
        <br />
        <ul>
          <li>Choose 1 photo style from a list of preset styles.</li>
          <li>
            Personalize your AI photos by uploading your selfie, choosing a
            gender and the aspect ratio.
          </li>
          <li>
            Wait just less than 1 minute for your AI photos to be generated!
          </li>
        </ul>
        <p>
          You can also write a text prompt to design your own photo style and
          create your own unique AI image.
        </p>
      </>
    )
  },
  {
    head: "What technology does HyperBooth use?",
    body: "HyperBooth is powered by our proprietary generative AI multi-modal foundation model, built and trained in-house by our leading team of AI researchers and engineers."
  },
  {
    head: "What makes HyperBooth different from other AI image generators?",
    body: "Unlike other AI image generators, HyperBooth only requires users to upload one single image, only takes 30 seconds to generate an AI image and requires no prior AI or coding knowledge or skills."
  },
  {
    head: "What type of photo/selfie should I upload to create the AI photo?",
    body: (
      <>
        <p>
          Here are some guidelines on{" "}
          <Link
            href="https://blog.hyperbooth.ai/product/choosing-the-right-input-image-on-hyperbooth-ai/"
            target="_blank"
            className={styles.link}
          >
            uploading the right input photo
          </Link>
          :
        </p>
        <ul>
          <li>Uploaded image should be a frontal portrait of a person.</li>
          <li>Uploaded image should not have more than one person.</li>
          <li>Face area in the Uploaded image should not be too small.</li>
          <li>
            Facial features should be clear (not covered by sunglasses, mask,
            cap etc.).
          </li>
          <li>Facial expressions should be natural.</li>
          <li>Avoid uploading images with too much nudity.</li>
          <li>Uploaded image should not be fuzzy.</li>
        </ul>
      </>
    )
  },
  {
    head: "How long does it take to create AI photos on HyperBooth.Ai?",
    body: "Based on our current processing capability, it takes about 30 seconds for each generation of 4 AI photos, and you could have multiple concurrent generations!"
  },
  {
    head: "What will you do with selfies/photos that I upload?",
    body: (
      <span>
        We only use your uploaded photos to create AI photos for you. We do not
        share or store your images. You can find out more about our{" "}
        <Link
          href="https://www.hyperbooth.ai/privacy-policy"
          target="_blank"
          className={styles.link}
        >
          Privacy Policy
        </Link>
        .
      </span>
    )
  },
  {
    head: "Can I use HyperBooth.Ai for free? What do I gain from a paid subscription?",
    body: (
      <>
        <p>
          Every user gets 5 free generation attempts per day (~20 AI photos) on
          the first 7 days after signing up. Our{" "}
          <Link
            href="https://www.hyperbooth.ai/pricing"
            target="_blank"
            className={styles.link}
          >
            paid subscribers
          </Link>{" "}
          get extra benefits:
        </p>
        <ul>
          <li>More credits to generate more AI images</li>
          <li>Faster AI image generation</li>
          <li>Upscaling your AI images</li>
          <li>Ability to do multiple concurrent generations</li>
        </ul>
      </>
    )
  },
  {
    head: "Where can I use HyperBooth.Ai photos?",
    body: (
      <>
        <p>
          HyperBooth takes realistic{" "}
          <Link
            href="https://blog.hyperbooth.ai/case-studies/ai-headshot-generator-hyperbooth/"
            target="_blank"
            className={styles.link}
          >
            AI headshots
          </Link>{" "}
          and photoshoot images, so if you&lsquo;re looking at your next
          LinkedIn photo or a profile picture for your CV, you can get great
          looking professional photos for yourself in a single click.
        </p>
        <br />
        <p>
          HyperBooth is also great in maintaining character consistency and this
          is especially useful for anyone creating{" "}
          <Link
            href="https://blog.hyperbooth.ai/case-studies/ai-influencer/"
            target="_blank"
            className={styles.link}
          >
            AI influencers
          </Link>
          ,{" "}
          <Link
            href="https://blog.hyperbooth.ai/case-studies/ai-cartoon-generator/"
            target="_blank"
            className={styles.link}
          >
            AI cartoon
          </Link>{" "}
          character illustrations, comics or storyboards.
        </p>
      </>
    )
  },
  {
    head: "Can I create my own unique AI art on HyperBooth.Ai?",
    body: (
      <p>
        HyperBooth hosts over 300+ pre-trained photo styles for users to
        explore. We also offer free{" "}
        <Link
          href="https://blog.hyperbooth.ai/product/how-to-write-a-good-text-prompt-on-hyperbooth-ai/"
          target="_blank"
          className={styles.link}
        >
          text-to-image
        </Link>{" "}
        (Custom Style feature) that you can use to create stunning{" "}
        <Link
          href="https://blog.hyperbooth.ai/product/ai-art/"
          target="_blank"
          className={styles.link}
        >
          AI art
        </Link>{" "}
        of anything you can imagine.
      </p>
    )
  },
  {
    head: "How can I get involved in the HyperBooth.Ai community?",
    body: (
      <p>
        We&lsquo;re active on social media and love to see your unique AI art
        made with HyperBooth. Follow us @hyperbooth_ai on{" "}
        <Link
          href="https://www.instagram.com/hyperbooth_ai/"
          target="_blank"
          className={styles.link}
        >
          Instagram
        </Link>
        /
        <Link
          href="https://www.tiktok.com/@hyperbooth_ai"
          target="_blank"
          className={styles.link}
        >
          TikTok
        </Link>
        /
        <Link
          href="https://twitter.com/HyperBooth_AI"
          target="_blank"
          className={styles.link}
        >
          X
        </Link>{" "}
        or use the hashtag #HyperBoothAI to share your artwork, collaborate,
        interact and connect with other amazing AI artists. We also share tips
        and tricks, post blogs and competitions on our pages. Join the community
        to inspire your next art piece.
      </p>
    )
  }
];
