import React from "react";

import { useAuth } from "@shared-lib/auth";
import { Button } from "@ui/Button";
import { Crown } from "@ui/icons/Crown";
import { WEB_LINKS } from "@constants/index";

import styles from "./CreditCapsule.module.scss";
import { useRouter } from "next/router";
import { Credit } from "@ui/icons/Credit";
import classNames from "classnames";

const CreditCapsule = () => {
  const router = useRouter();
  const { user } = useAuth();

  return (
    user?.credits !== undefined && (
      <div className={classNames(styles.upgradeContainer, "sm-hide")}>
        <Button
          mobileSize="small"
          type="primary"
          onClick={() => {
            router.push(WEB_LINKS.SUBSCRIPTION);
          }}
        >
          <Crown size={16} color="white" /> Upgrade
          <span className={styles.verticalDivider}>|</span>
          <Credit size={16} />
          <span>{user?.credits || 0}</span>
        </Button>
      </div>
    )
  );
};

export { CreditCapsule };
