import React from "react";
import { Roboto } from "next/font/google";
import cn from "classnames";
import {
  Root,
  Trigger,
  Portal,
  Content,
  Arrow,
  Close,
  PopoverContentProps,
  PopoverArrowProps
} from "@radix-ui/react-popover";
import { ArrowSvg } from "./ArrowSvg";
import styles from "./Popover.module.scss";

const inter = Roboto({
  weight: ["100", "500", "400"],
  subsets: ["latin"]
});

interface Props {
  isOpen?: boolean;
  isDisabled?: boolean;
  closeWhenInteractContent?: boolean;
  showArrow?: boolean;
  arrowProps?: { color?: string };
  content: React.ReactNode;
  contentProps: Pick<
    PopoverContentProps,
    "align" | "alignOffset" | "side" | "sideOffset" | "onInteractOutside"
  >;
  children: React.ReactNode | React.ReactNode[];
}

const ARROW_WIDTH = 20;
const ARROW_HEIGHT = 11;

export const Popover: React.FC<Props> = ({
  isOpen,
  isDisabled,
  children,
  closeWhenInteractContent = false,
  content,
  contentProps,
  showArrow = true,
  arrowProps
}) => {
  return (
    <Root open={!isDisabled && isOpen}>
      <Trigger className={styles.trigger}>{children}</Trigger>
      <Portal>
        <Content
          className={cn(inter.className, styles.content)}
          {...contentProps}
        >
          <>
            {closeWhenInteractContent ? (
              <Close asChild className={styles.close}>
                {content}
              </Close>
            ) : (
              content
            )}
            {showArrow && (
              <Arrow
                asChild
                className={styles.arrow}
                width={ARROW_WIDTH}
                height={ARROW_HEIGHT}
              >
                <ArrowSvg
                  width={ARROW_WIDTH}
                  height={ARROW_HEIGHT}
                  color={arrowProps?.color}
                />
              </Arrow>
            )}
          </>
        </Content>
      </Portal>
    </Root>
  );
};
