import styles from "./TextPromptAnimation.module.scss";
import { useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export default function TextPromptAnimation() {
  //const ref = useRef(null);

  const ref = useRef<HTMLVideoElement>(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) ref?.current?.play();
    if (!isInView) ref?.current?.pause();
    return () => {
      ref?.current?.pause();
    };
  }, [isInView]);
  return (
    <div className={styles.container}>
      <div className={`${styles.images}`}>
        <video playsInline ref={ref} muted loop>
          <source
            src="images/landingpage/section7/text_prompt.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}
