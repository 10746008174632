import { ReactNode, memo, useEffect, useMemo, useState } from "react";
import styles from "./Tabs.module.scss";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  children: React.ReactElement<PropsTab>[];
  defaultTabTitle?: string;
}
export const Tabs: React.FC<Props> = ({ children, defaultTabTitle }) => {
  if (children.length == 0)
    throw new Error("Tabs component must have Tab Children");

  //tab title
  const [currentComponent, setCurrentComponent] = useState<
    React.ReactElement<PropsTab>
  >(children.find((x) => x.props.title == defaultTabTitle) || children[0]);

  return (
    <main className={styles.tabs}>
      <nav className={styles.tabNavigation}>
        {children.map((x) => (
          <button
            className={
              x.props.title == currentComponent?.props.title
                ? styles.active
                : ""
            }
            key={x.props.title}
            onClick={() => setCurrentComponent(x)}
          >
            {x.props.title}
            <hr></hr>
          </button>
        ))}
      </nav>
      <section>{currentComponent}</section>
    </main>
  );
};

interface PropsTab {
  children: React.ReactNode;
  title: string;
}
export const Tab: React.FC<PropsTab> = ({ children, title }) => {
  return <section>{children}</section>;
};
