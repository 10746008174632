import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";

import { Instagram } from "@ui/icons/Instagram";
import { WEB_LINKS } from "@constants/index";

import styles from "./mobileMenu.module.scss";
import Link from "next/link";
import { logAnalytics } from "@hypergai-frontend-mvp/shared-lib";
import { Tiktok } from "@ui/icons/Tiktok";
import { Youtube } from "@ui/icons/Youtube";
import { useAuth } from "@shared-lib/auth";
const contentVariant = {
  contentInitial: {
    x: "-100vw"
  },
  contentAnimate: {
    x: 0
  },
  contentExit: {
    x: "-100vw"
  }
};

export default function MobileMenu({ show = false }: { show: boolean }) {
  const router = useRouter();
  const iconSize = 35;
  const { user } = useAuth();
  const pricingLink = user ? WEB_LINKS.SUBSCRIPTION : WEB_LINKS.PRICING;

  return (
    <AnimatePresence>
      {show && (
        <motion.nav
          variants={contentVariant}
          initial="contentInitial"
          animate="contentAnimate"
          exit="contentExit"
          transition={{ duration: 0.3, type: "tween" }}
          className={styles.mobileMenu}
        >
          <div className={styles.links}>
            <Link
              target="_blank"
              className={styles.buttonMenu}
              href="/community"
            >
              Community
            </Link>
            <Link
              target="_blank"
              className={styles.buttonMenu}
              href={pricingLink}
            >
              Pricing
            </Link>
            <Link
              target="_blank"
              className={styles.buttonMenu}
              href={WEB_LINKS.BLOG}
            >
              Blog
            </Link>
          </div>
          <hr></hr>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "48px" }}
          >
            <Link
              className={styles.socialMediaBtn}
              href={WEB_LINKS.INSTAGRAM}
              onClick={() => {
                logAnalytics({ eventName: "instagram_button_clicks" });
              }}
              target="_blank"
            >
              <Instagram size={iconSize} color="white" />
            </Link>
            <Link
              className={styles.socialMediaBtn}
              href={WEB_LINKS.TIKTOK}
              onClick={() => {
                logAnalytics({ eventName: "tiktok_button_clicks" });
              }}
              target="_blank"
            >
              <Tiktok size={iconSize} color="white" />
            </Link>
            <Link
              className={styles.socialMediaBtn}
              onClick={() => {
                logAnalytics({ eventName: "youtube_button_clicks" });
              }}
              href={WEB_LINKS.YOUTUBE}
              target="_blank"
            >
              <Youtube size={iconSize} color="white" />
            </Link>
          </div>
          <hr></hr>
          <div className={styles.links}>
            <button
              className={styles.hollowButton}
              onClick={() => {
                router.push(WEB_LINKS.LOGIN);
              }}
            >
              Log In
            </button>
            <button
              className={styles.heroButtonSmall}
              onClick={() => {
                router.push(WEB_LINKS.SIGN_UP);
              }}
            >
              Sign Up
            </button>
          </div>
        </motion.nav>
      )}
    </AnimatePresence>
  );
}
