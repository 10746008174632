import styled from "styled-components";
import Image from "next/image";
interface IconButtonProps {
  disabled?: boolean;
  $active?: boolean;
}

// main container
export const NavButtonStyle = styled.div<IconButtonProps>`
  border: 0px;
  border-radius: 8px;
  padding: 0px;
  cursor: pointer;
  background-color: inherit;
  display: flex;
  justify-content: center;
  &:hover {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.grey2};
  }

  // margin-right: 0.8rem;

  // if active.
  background-color: ${(props) =>
    props.$active
      ? ` 
    #e0225b;
    &:hover {
       background-color:#e0225b;
    }
    `
      : ""};

  // if disabled.
  background-color: ${(props) =>
    props.disabled
      ? `;
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color:inherit;
    }
    `
      : ""};
`;

export const Arrow = styled(Image)`
  margin-bottom: 1.2rem;
  margin-right: 1rem;
`;
