import styled from "styled-components";

export const TipsBarStyled = styled.div`
  padding: ${({ theme }) => theme.size.padding1};
  display: flex;
  align-items: center;
  background-color: #f62323;
  border-radius: 12px;
  opacity: 1;
  min-width: 2.4rem;
  gap: ${({ theme }) => theme.size.padding1};
  margin-top: ${({ theme }) => theme.size.padding3};
  // transform: translateX(-7rem);
  flex-flow: row;
  justify-content: space-between;
  height: fit-content;
  z-index: 1;
  div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  button {
    position: relative;
    top: 3px;
  }
`;
