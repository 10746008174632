import styled from "styled-components";

export const ProfileButton = styled.button`
  background: #18192b;
  color: white;
  border: 0px;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
`;
