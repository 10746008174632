import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Checkbox } from "@hypergai-frontend-mvp/ui-framework";
import { useRouter } from "next/router";

import styles from "./user-survey.module.scss";
import { post } from "@shared-lib/api";
import { logAnalytics } from "@hypergai-frontend-mvp/shared-lib";

type Form1 = {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
  5: boolean;
  6: boolean;
  7: boolean;
  8: boolean;
  others: boolean;
  othersInput: string;
};

type Form2 = {
  1: boolean;
  2: boolean;
  3: boolean;
  5: boolean;
  6: boolean;
  others: boolean;
  othersInput: string;
};

type Form3 = {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
  5: boolean;
  6: boolean;
  others: boolean;
  othersInput: string;
};

type Form4 = {
  othersInput: string;
};

export default function UserSurvey({ completed }: { completed: () => void }) {
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const router = useRouter();
  const FormControl1 = useForm<Form1>();
  const FormControl2 = useForm<Form2>();
  const FormControl3 = useForm<Form3>();
  const FormControl4 = useForm<Form4>();

  function validateForm(data: Form1 | Form2 | Form3) {
    if (Object.values(data).every((v) => v == false)) {
      setError("Please select at least 1 option");
    } else if (data.others && !data.othersInput) {
      setError(`Please elaborate on "Others"`);
    } else {
      setError("");
      router.push({
        query: { ...router.query, surveypage: "page" + (page + 1) }
      });
    }
  }

  function routePages(url: string) {
    if (url.includes("page1")) {
      setPage(1);
    } else if (url.includes("page2")) {
      setPage(2);
    } else if (url.includes("page3")) {
      setPage(3);
    } else {
      setPage(0);
    }
  }

  // function to send survey API
  async function sendSurveyApi(formType: number) {
    const formcontrols = new Map();
    formcontrols.set(1, FormControl1);
    formcontrols.set(2, FormControl2);
    formcontrols.set(3, FormControl3);
    formcontrols.set(4, FormControl4);

    const form = formcontrols.get(formType).getValues();
    const selected: number[] = [];
    for (const [key, value] of Object.entries(form)) {
      //console.log(`${key}: ${value}`);
      if (value === true) {
        selected.push(parseInt(key));
      }
    }
    const body = {
      question: formType,
      choose: selected,
      content: form["othersInput"]
    };
    console.log("body:", body);
    try {
      await post("/hyper-booth/addSurveyAnswer", body);
      //do recursion if its less then 4.
      if (formType < 4) {
        sendSurveyApi(formType + 1);
      } else {
        logAnalytics({ eventName: "survey_completed" });
        completed && completed();
      }
    } catch (error) {
      console.log(error);
      setError("An Error Occured");
    }
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      routePages(url);
    };

    router.events.on("routeChangeStart", handleRouteChange);
    // If the component is unmounted, unsubscribe

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  return (
    <div className={styles.centerBlackContainer}>
      <main className={styles.surveyContainer}>
        <h1 className={`${styles.surveyTitle}`}>Tell us about you</h1>
        <h2 className={`${styles.surveySubtitle}`}>
          Just 4 simple questions to get you started
        </h2>
        <div className={styles.progressStepper}>
          {[...Array(4).keys()].map((x) => (
            <div
              key={x}
              className={
                page >= x
                  ? `${styles.step} ${styles.highlight}`
                  : `${styles.step}`
              }
            />
          ))}
        </div>
        {page == 0 && (
          <form
            className={styles.surveyForm}
            onSubmit={FormControl1.handleSubmit(validateForm)}
          >
            <main className={styles.surveyBox}>
              <h1 className={`${styles.surveyTitle} ${styles.small}`}>
                What describes you best?
              </h1>
              Select at least 1 answer
              <div className={styles.surveyContent}>
                {[
                  { label: "Designer", id: "1" },
                  { label: "Artist", id: "2" },
                  { label: "Photographer", id: "3" },
                  { label: "Product Manager", id: "4" },
                  { label: "Developer", id: "5" },
                  { label: "Marketer", id: "6" },
                  { label: "Entrepreneur", id: "7" },
                  { label: "Student", id: "8" }
                ].map((x) => (
                  <Checkbox
                    key={x.id}
                    className="mid"
                    type={"checkbox"}
                    id={x.id}
                    label={x.label}
                    {...FormControl1.register(x.id as "others" | "othersInput")}
                  ></Checkbox>
                ))}
                <div className={styles.othersField}>
                  <Checkbox
                    className="mid"
                    type={"checkbox"}
                    id={`Others`}
                    label={`Others:`}
                    {...FormControl1.register("others")}
                  ></Checkbox>
                  <input
                    className={styles.lineInput}
                    type="text"
                    {...FormControl1.register("othersInput")}
                  ></input>
                </div>
              </div>
            </main>
            {error && <span className={styles.error}>{error}</span>}
            <Button type="primary" desktopSize="large" isBlock>
              Next
            </Button>
          </form>
        )}

        {page == 1 && (
          <form
            className={styles.surveyForm}
            onSubmit={FormControl2.handleSubmit(validateForm)}
          >
            <main className={styles.surveyBox}>
              <h1 className={`${styles.surveyTitle} small`}>
                What industries are you working in?
              </h1>
              Select at least 1 answer
              <div className={styles.surveyContent}>
                {[
                  { label: "Design", id: "1" },
                  { label: "Gaming", id: "2" },
                  { label: "Technology", id: "3" },
                  { label: "Marketing", id: "4" },
                  { label: "E-Commerce", id: "5" },
                  { label: "Finance", id: "6" }
                ].map((x) => (
                  <Checkbox
                    key={x.id}
                    className="mid"
                    type={"checkbox"}
                    id={x.id}
                    label={x.label}
                    {...FormControl2.register(x.id as "others" | "othersInput")}
                  ></Checkbox>
                ))}

                <div className={styles.othersField}>
                  <Checkbox
                    className="mid"
                    type={"checkbox"}
                    id={`Others`}
                    label={`Others:`}
                    {...FormControl2.register("others")}
                  ></Checkbox>
                  <input
                    className={styles.lineInput}
                    type="text"
                    {...FormControl2.register("othersInput")}
                  ></input>
                </div>
              </div>
            </main>
            {error && <span className={styles.error}>{error}</span>}
            <Button type="primary" desktopSize="large" isBlock>
              Next
            </Button>
          </form>
        )}

        {page == 2 && (
          <form
            className={styles.surveyForm}
            onSubmit={FormControl3.handleSubmit(validateForm)}
          >
            <main className={styles.surveyBox}>
              <h1 className={styles.surveyTitle}>
                What tools are you most familiar with?
              </h1>
              Select at least 1 answer
              <div className={styles.surveyContent}>
                {[
                  { label: "Figma / Sketch etc", id: "1" },
                  { label: "Photoshop / Illustrator etc", id: "2" },
                  { label: "Cinema4D", id: "3" },

                  { label: "Canva", id: "5" },
                  { label: "Miro / FigJam / Mural", id: "6" },
                  { label: "Midjourney / Runway etc", id: "7" }
                ].map((x) => (
                  <Checkbox
                    key={x.id}
                    className="mid"
                    type={"checkbox"}
                    id={x.id}
                    label={x.label}
                    {...FormControl3.register(x.id as "others" | "othersInput")}
                  ></Checkbox>
                ))}

                <div className={styles.othersField}>
                  <Checkbox
                    className="mid"
                    type={"checkbox"}
                    id={`Others`}
                    label={`Others:`}
                    {...FormControl3.register("others")}
                  ></Checkbox>
                  <input
                    className={styles.lineInput}
                    type="text"
                    {...FormControl3.register("othersInput")}
                  ></input>
                </div>
              </div>
            </main>
            {error && <span className={styles.error}>{error}</span>}
            <Button type="primary" desktopSize="large" isBlock>
              Next
            </Button>
          </form>
        )}

        {page == 3 && (
          <>
            <main className={styles.surveyBox}>
              <h1
                className={styles.surveyTitle}
                style={{ marginBottom: "2rem" }}
              >
                Why are you interested in HyperBooth.Ai?
              </h1>
              <form className={styles.surveyForm}>
                <textarea
                  className={styles.surveyTextBox}
                  {...FormControl4.register("othersInput", {
                    required: "Input required"
                  })}
                ></textarea>
              </form>
            </main>
            {error && <span className={styles.error}>{error}</span>}
          </>
        )}

        {page == 3 && (
          <Button
            onPointerDown={() => {
              if (FormControl4.formState.isValid) sendSurveyApi(1);
            }}
            type="primary"
            desktopSize="large"
            isBlock
            disabled={!FormControl4.formState.isValid}
          >
            Start Generating
          </Button>
        )}
      </main>
    </div>
  );
}
